<div>
  <div
    [innerHTML]="
      'components.consumption.index.monthlyInstallment.warning.' + (messageType | camelCase) + '.content' | translate
    "
    class="mt-2 mb-0 sm:mt-12 sm:mb-8"
  ></div>
  <button (click)="continue()" class="btn btn-primary font-bold inline-block w-full sm:w-auto mt-8">
    {{ 'general.continue' | translate }}
  </button>
</div>

import { Component, DestroyRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { ControlContainer, FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { SharedModule } from '@app/shared/shared.module';
import { Direction, MeterType } from '@app/modules/customer-zone/consumption/models/deliveryPoint.interface';
import { RegisterType } from '@app/modules/customer-zone/consumption/models/consumption.interface';
import { InputMeterStyle } from '@app/shared/components/input-meter-index/input-meter-index.component';
import { TranslateModule } from '@ngx-translate/core';
import { MoveFormFacade } from '@app/core/facade/move-form.facade';
import { InputErrorComponent } from '@app/shared/components/input-error/input-error.component';
import * as FormUtils from '@app/shared/utils/utils.form';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { GasMeterFormGroup, IndexesFormGroup, MetersForm } from '../new-meters.interface';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

@Component({
  selector: 'app-gas-meter',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, NgOptimizedImage, SharedModule, TranslateModule, InputErrorComponent],
  templateUrl: './gas-meter.component.html',
  styleUrls: ['./gas-meter.component.scss'],
})
export class GasMeterComponent implements OnInit {
  @Input() submittedForm: boolean;
  @Output() formValidityEmitter: EventEmitter<boolean> = new EventEmitter(false);
  protected readonly InputMeterStyle = InputMeterStyle;
  eanErrorTranslations = this.moveFormFacade.getMoveFormService().getErrorMessagesForEan();
  constructor(
    private moveFormFacade: MoveFormFacade,
    private controlContainer: ControlContainer,
    private formBuilder: FormBuilder,
    private destroyRef: DestroyRef
  ) {}

  get metersFormGroup(): FormGroup<MetersForm> {
    return this.controlContainer.control as FormGroup<MetersForm>;
  }

  get meterNumberControl(): FormControl<string> {
    return this.metersFormGroup.controls?.gas.controls.number;
  }

  get indexesControls(): FormGroup<IndexesFormGroup>[] {
    return this.metersFormGroup.controls?.gas.controls.indexes.controls;
  }

  get meterNumberInteracted(): boolean {
    return this.meterNumberControl.touched || this.meterNumberControl.dirty;
  }

  ngOnInit(): void {
    const gasGroup = this.createGasFormGroup();
    this.metersFormGroup.setControl('gas', gasGroup);
    this.setFormValidity();
    this.emitFormValidity();
    this.subscribeToLangChange();
  }

  private setFormValidity(): void {
    const addOrRemoveValidators = (noMeter: boolean): void => {
      if (noMeter) {
        FormUtils.removeValidator(this.meterNumberControl, [Validators.required]);
      } else {
        FormUtils.addValidator(this.meterNumberControl, [Validators.required]);
      }
    };
    const noMeter = this.metersFormGroup.controls.gas.controls.noMeter;
    addOrRemoveValidators(noMeter.value);
    noMeter.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((noMeter: boolean) => {
      addOrRemoveValidators(noMeter);
    });
  }

  private createGasFormGroup(): FormGroup<GasMeterFormGroup> {
    const state = this.moveFormFacade.state$.value;
    const gasForm = state?.form?.newMeters?.meters?.gas;
    const index = gasForm?.indexes?.[0];
    const indexValue = index?.value;
    const [unit, decimal] = indexValue ? indexValue?.split('.') : [null, null];
    const value = indexValue
      ? indexValue
      : index?.unit || index?.decimal
      ? `${index?.unit ?? 0}.${index?.decimal ?? 0}`
      : null;
    return this.formBuilder.group<GasMeterFormGroup>({
      noMeter: new FormControl(gasForm?.noMeter || false),
      ean: new FormControl(gasForm?.ean || null),
      number: new FormControl(gasForm?.number, [Validators.required]),
      meterType: new FormControl(MeterType.MONO, [Validators.required]),
      indexes: this.formBuilder.array<FormGroup<IndexesFormGroup>>([
        this.formBuilder.group<IndexesFormGroup>({
          timeFrame: new FormControl(RegisterType.MONO),
          type: new FormControl(Direction.reading),
          unit: new FormControl(index?.unit ?? unit ?? null),
          decimal: new FormControl(index?.decimal ?? decimal ?? null),
          value: new FormControl(gasForm?.indexes?.[0]?.value ?? value ?? null),
        }),
      ]),
    });
  }

  private emitFormValidity(): void {
    const gasGroup = this.metersFormGroup.controls.gas;
    this.formValidityEmitter.emit(gasGroup.valid);
    gasGroup.valueChanges.subscribe(() => this.formValidityEmitter.emit(gasGroup.valid));
  }

  private subscribeToLangChange(): void {
    this.moveFormFacade.translate.onLangChange.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      this.eanErrorTranslations = this.moveFormFacade.getMoveFormService().getErrorMessagesForEan();
    });
  }
}

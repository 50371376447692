<ng-container *ngIf="!isFinished; else finished">
  <app-upcoming-payment
    *ngIf="paymentPlan?.upcomingPayment"
    [upcomingPayment]="paymentPlan?.upcomingPayment"
    [billingDetails]="paymentPlan?.billingDetails"
    [paymentId]="paymentPlan?.paymentId"
  >
  </app-upcoming-payment>

  <app-payment-plan-details [paymentPlan]="paymentPlan"> </app-payment-plan-details>

  <span
    *ngIf="paymentPlan?.paymentId"
    (click)="downloadPDF(paymentPlan?.paymentId)"
    class="text-center btn-text inline-block w-full mt-12 text-primary font-medium cursor-pointer"
  >
    {{ 'components.paymentPlan.overview.paymentPlanDetails.downloadInvoice' | translate }}
  </span>
</ng-container>

<ng-template #finished>
  <div class="flex">
    <div class="flex-1 flex items-center">
      <img src="./assets/img/thumbup.svg" width="60" height="60" class="inline-block" />
      <div class="inline-block ml-8">
        <h3 class="te-text-gradient-home-secondary text-2xl font-medium inline-block w-full">
          {{ 'components.paymentPlan.overview.paymentPlanDetails.finished.allGood' | translate }}
        </h3>
        <app-related-invoices
          *ngIf="paymentPlan?.relatedInvoiceIds?.length"
          [relatedInvoices]="paymentPlan?.relatedInvoiceIds"
        >
        </app-related-invoices>
      </div>
    </div>
    <div class="text-right">
      <span class="text-base font-medium inline-block w-full">
        {{ 'components.paymentPlan.overview.paymentPlanDetails.finished.toPay' | translate }}
      </span>
      <span class="w-full font-display font-normal text-5xl te-text-gradient-home-secondary inline-block"> 0€ </span>
    </div>
  </div>
</ng-template>

<header
  class="py-4 bg-white relative md:py-8 z-30 md:z-auto"
  [class.has-submenu]="isSubmenuActive"
  [ngClass]="[
    mobileMenu ? 'mobile-menu-open' : 'mobile-menu-close',
    mobileSubmenu ? 'mobile-submenu-open' : 'mobile-submenu-close'
  ]"
>
  <div class="header__wrapper md:relative has-contracts">
    <div class="px-8 xl:max-w-screen-xl xl:w-full xl:m-auto">
      <div class="md:flex items-center">
        <div class="flex md:block relative">
          <h1 class="mr-20 cursor-pointer" (click)="goHome()" [analyticsEvent]="'navigation:click:logo'">
            <img src="./assets/img/logo.svg" class="w-24 md:w-44" width="108" height="80" alt="TotalEnergies" />
          </h1>
          <button
            #menuToggler
            [class]="mobileMenu ? 'is-active' : 'is-inactive'"
            class="nav-button burger-menu ml-auto md:hidden"
            (click)="toggleMobileMenu()"
            type="button"
            role="button"
            aria-label="open/close navigation"
            [analyticsEvent]="'navigation:expand-collapse:burger'"
          >
            <svg width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1 13H19" stroke="#ED0000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M1 7H19" stroke="#ED0000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M1 1H19" stroke="#ED0000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </button>
        </div>
        <div #menu class="menu flex flex-col md:block md:flex-1">
          <div class="flex-1">
            <div class="flex flex-col md:block md:flex-1">
              <div
                class="relative z-10 order-last flex md:z-20 md:order-first md:border-b md:border-grey md:pb-4 md:mb-4"
              >
                <span class="hidden text-primary md:block"></span>
                <app-secondary-menu
                  class="flex-1 z-10 md:text-right"
                  [content$]="content$"
                  [referencesDetails$]="referencesDetails$"
                  [userInfo$]="userInfo$"
                  [activeReference]="activeReference"
                  [currentLanguage]="currentLanguage"
                  [mobileSubmenu]="mobileSubmenu"
                  [mobileActiveSubmenu]="mobileActiveSubmenu"
                  (activeReferenceEmitter)="switchReference($event)"
                  (currentLanguageEmitter)="switchLanguage($event)"
                  (mobileMenuEmitter)="toggleMobileMenu($event)"
                  (mobileSubmenuEmitter)="mobileCloseSubmenu($event)"
                  (mobileActiveSubmenuEmitter)="mobileShowSubmenu($event)"
                >
                </app-secondary-menu>
              </div>
              <div class="md:flex">
                <ng-container *ngIf="userInfo$ | async">
                  <app-primary-menu
                    class="z-0"
                    [referencesDetails]="referencesDetails$ | async"
                    [currentLanguage]="currentLanguage"
                    [mobileActiveSubmenu]="mobileActiveSubmenu"
                    [isMobile]="(screenSizeService.screensize | async)?.width <= isMobileVersion"
                    (submenuEmitter)="showSubmenu($event)"
                    (mobileMenuEmitter)="toggleMobileMenu($event)"
                    (mobileSubmenuEmitter)="mobileCloseSubmenu($event)"
                    (mobileActiveSubmenuEmitter)="mobileShowSubmenu($event)"
                  >
                  </app-primary-menu>
                </ng-container>
                <app-lang-switcher class="relative z-10 hidden md:block md:ml-auto"> </app-lang-switcher>
              </div>
            </div>
          </div>
          <div class="flex items-center md:hidden">
            <app-lang-switcher
              class="ml-auto md:hidden"
              [type]="langSwitcherType.inline"
              (switchLangEvent)="toggleMobileMenu()"
            >
            </app-lang-switcher>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>

<div [class.active]="question !== null">
  <div class="panel overflow-y-auto">
    <span class="bg-white close close-btn" #close></span>
    <h3 class="text-grey-dark text-2xl font-semibold mb-8">
      {{ 'components.homeProfileForm.panel.update.label' | translate }}
    </h3>
    <div class="bg-white p-8 sm:p-12 rounded-2xl">
      <h2 class="mb-8 font-display font-normal text-4xl te-text-gradient-home inline-block">
        {{ 'components.homeProfileForm.list.' + question?.key + '.panelTitle' | translate }}
      </h2>
      <div>
        <form *ngIf="form" [formGroup]="form" (ngSubmit)="handleSubmit()" novalidate>
          <div *ngIf="singleQuestion; else multiQuestions">
            <app-home-profile-question-type [form]="form" [question]="singleQuestion"> </app-home-profile-question-type>
          </div>
          <ng-template #multiQuestions>
            <div>
              <div *ngFor="let q of question?.value" class="inline-block w-full mb-8 last:mb-0">
                <div
                  *ngIf="q['description']"
                  [innerHTML]="'components.homeProfileForm.list.' + q['key'] + '.description' | translate"
                  class="mb-8"
                ></div>
                <app-home-profile-question-type [form]="form" [question]="q" [category]="question?.category">
                </app-home-profile-question-type>
              </div>
            </div>
          </ng-template>
          <div class="text-left mt-12 pt-12 border-t-2 border-grey-light">
            <button
              type="submit"
              [attr.disabled]="
                !form.valid || (form.pristine && !hasSuggestedValue && !pristineCanBeSubmitted) ? '' : null
              "
              [class.not-submittable]="!form.valid || (form.pristine && !hasSuggestedValue && !pristineCanBeSubmitted)"
              [analyticsEvent]="'building:click:update-home-profile'"
              class="btn btn-primary inline-block w-full font-bold mb-4 md:mb-0 md:w-72"
            >
              {{ 'general.update' | translate }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div #close class="backdrop"></div>
</div>

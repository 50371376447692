<div class="bg-white">
  <div class="hidden md:flex md:text-xs md:py-2 text-grey-dark60">
    <div class="w-1/6">{{ 'components.monitoring.monthlyReport.table.month' | translate }}</div>
    <div class="w-1/6">{{ 'components.monitoring.monthlyReport.table.numberOfCharges' | translate }}</div>
    <div class="w-1/6">{{ 'components.monitoring.monthlyReport.table.consumption' | translate }}</div>
    <div class="w-2/6"></div>
    <div class="w-1/6 text-right">{{ 'components.monitoring.monthlyReport.table.price' | translate }}</div>
  </div>

  <ng-container *ngFor="let session of chargingStationSessionReports$ | async">
    <div class="flex w-full md:flex border-t border-grey border-solid items-center">
      <div class="flex-auto w-1/2 md:w-1/6 py-6">
        {{ session.start | date : 'MMMM yyyy' : '' : locale | titlecase }}
      </div>
      <div class="hidden md:flex w-1/6 py-6 px-2 text-right">
        {{ session.nbSession }}
      </div>
      <div class="hidden md:flex w-1/6 py-6 text-right">
        {{ session.totalEnergy / 1000 | number : '1.0-2' : 'fr-BE' }} kWh
      </div>
      <div class="hidden md:flex w-2/6 py-6 px-5">
        <div class="w-full">
          <div
            class="h-4 rounded-4xl bg-turquoise"
            [style.width.%]="(session.totalEnergy / (maxKwh | async)) * 100"
          ></div>
        </div>
      </div>
      <div class="flex-auto font-bold text-right py-6 w-1/2 md:w-1/6 md:text-right md:font-normal">
        {{ session.totalPrice | number : '1.0-2' : 'fr-BE' }} &euro;
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="(chargingStationSessionReports$ | async)?.length === 0">
    <div class="block w-full md:flex border-t border-solid items-center px-12">
      <div class="w-full py-16 text-sm text-center">
        {{ 'components.monitoring.monthlyReport.table.empty' | translate }}
      </div>
    </div>
  </ng-container>
</div>

<div class="relative h-full mb-8 md:mb-8">
  <div class="card-primary-small relative">
    <app-tooltip
      [tooltip]="
        'components.widgets.meterReadingChart.tooltip.' +
        (activeEnergyType === EnergyType.ELECTRICITY ? meterConfig : 'gas')
      "
      class="absolute right-4 top-4"
    ></app-tooltip>
    <div class="md:flex flex-col sm:flex-row md:items-center border-b border-grey mt-4 mb-8 pb-8 mx-8 relative">
      <div class="w-full md:w-auto md:inline-block">
        <te-switcher
          class="inline-block w-auto mt-8 md:mt-0"
          [options]="energyOptions"
          [active]="activeEnergyType"
          [translationPrefix]="'general.product.'"
          (switchAction)="switchEnergy($event)"
        >
        </te-switcher>
      </div>
      <div
        *ngIf="lineChartLabels?.length"
        class="mt-8 w-full mx-auto font-medium text-lg text-grey text-center md:mt-0 md:w-auto lg:absolute lg:left-1/2 lg:-translate-x-1/2"
      >
        {{
          'components.widgets.meterReadingChart.title'
            | translate
              : {
                  start:
                    lineChartLabels[0]
                    | date : 'dd MMMM yyyy' : undefined : facade.translate.currentLang + '-BE'
                    | titlecase,
                  end:
                    lineChartLabels[1]
                    | date : 'dd MMMM yyyy' : undefined : facade.translate.currentLang + '-BE'
                    | titlecase
                }
        }}
      </div>
    </div>
    <div class="font-bold text-xs mb-4 -mt-2">
      {{ 'general.units.' + (activeEnergyType === EnergyType.ELECTRICITY ? 'kwh' : 'm3') | translate }}
    </div>
    <app-meter-reading-line-chart
      *ngIf="lineChartData; else loading"
      [lineChartData]="lineChartData"
      [lineChartLabels]="lineChartLabels"
      [lineChartAnnotations]="lineChartAnnotations"
      [energyType]="activeEnergyType"
    >
    </app-meter-reading-line-chart>
  </div>
</div>

<ng-template #loading>
  <app-loader-widget></app-loader-widget>
</ng-template>

<div class="article">
  <h3 class="text-lg text-grey-dark font-medium mb-10 leading-relaxed" [innerHTML]="article.title"></h3>
  <p class="text-sm text-grey-dark" [innerHTML]="article.teaser"></p>

  <p>
    <a
      href="{{ article.url }}"
      [analyticsEvent]="'blogBlock:click:' + article.url"
      target="_blank"
      class="inline-block w-full mt-8 text-primary font-medium"
    >
      <svg
        _ngcontent-byv-c239=""
        width="10"
        height="5"
        viewBox="0 0 10 5"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        class="inline-block -rotate-90 transform"
      >
        <path _ngcontent-byv-c239="" d="M0 0L5 5L10 0H0Z" fill="#ED0000"></path>
      </svg>
      {{ 'components.blog.readMore' | translate }}
    </a>
  </p>
</div>

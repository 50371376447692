<div class="reset-radio">
  <div
    appSlideToggle
    [collapsed]="value !== selectedValue"
    [isCloseable]="value !== selectedValue"
    class="box-shadow-small rounded-4xl bg-grey-light hover:te-border-gradient-home border-2 border-grey-light"
    [ngClass]="{ 'te-border-gradient-home': value === selectedValue }"
  >
    <div class="form-element input-radio-big">
      <input
        type="radio"
        [name]="name"
        [value]="value"
        [id]="value"
        [checked]="value === selectedValue"
        (change)="selectedValueChange.emit(value)"
      />
      <label #toggler [for]="value">
        <span [innerHTML]="label"></span>
        <span *ngIf="subtitle" class="inline-block w-full text-sm text-grey-dark60/60" [innerHTML]="subtitle"></span>
      </label>
    </div>
    <div #contentBox>
      <div #content>
        <section>
          <ng-content></ng-content>
        </section>
      </div>
    </div>
  </div>
</div>

import { Injectable } from '@angular/core';
import { UrlHandlingStrategy, UrlTree } from '@angular/router';
import { PlatformLocation } from '@angular/common';

@Injectable()
export class PreserveSpecificQueryParamUrlHandlingStrategy implements UrlHandlingStrategy {
  readonly queryParamsToPreserve = ['client_id'];
  constructor(private platformLocation: PlatformLocation) {}

  private get currentQueryParams(): string {
    return this.platformLocation?.search ?? '';
  }

  shouldProcessUrl(url: UrlTree): boolean {
    return true;
  }

  extract(url: UrlTree): UrlTree {
    if (!this.queryParamsToPreserve) {
      return url;
    }
    return this.queryParamsToPreserve.reduce((acc, next) => (acc = this.preserveParam(acc, next)), url);
  }

  merge(newUrlPart: UrlTree, wholeUrl: UrlTree): UrlTree {
    return newUrlPart;
  }

  private preserveParam(urlTree: UrlTree, param: string): UrlTree {
    const currentQueryParams = new URLSearchParams(this.currentQueryParams);
    if (currentQueryParams.has(param)) {
      urlTree.queryParams[param] = currentQueryParams.get(param);
    }
    return urlTree;
  }
}

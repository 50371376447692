<div class="sites-infos bg-white">
  <div class="px-8 xl:max-w-screen-xl xl:w-full xl:m-auto">
    <div class="items-center pb-8 pt-14 sm:pb-14 md:grid md:grid-cols-12 md:gap-16">
      <div class="mb-12 md:mb-0 md:col-span-8">
        <app-sites-switcher
          class="inline-block w-full ml-auto sites-switcher mb-8 md:mb-0 md:w-auto"
          [sites]="sites$ | async"
          [activeSite]="activeSite"
          [version]="versionSize.big"
          [activeReference]="activeReference"
          (switchSiteEmitter)="switchSite($event)"
        >
        </app-sites-switcher>
      </div>
    </div>
  </div>
</div>
<app-error-access [config]="errorAccessConfig"></app-error-access>

import { Component, DestroyRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { ErrorAccessComponent, ErrorAccessConfig } from '@app/shared/components/error-access/error-access.component';
import { SitesSwitcherComponent } from '@app/shared/components/sites-switcher/sites-switcher.component';
import { MainFacade } from '@app/core/facade/main.facade';
import { distinct, distinctUntilChanged, filter, skip, tap } from 'rxjs/operators';
import { Site } from '@app/modules/customer-zone/move/models/movein.interface';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Observable } from 'rxjs';
import { VersionSize } from '@app/shared/models/units.interface';
import { ExnatonService } from '@app/modules/customer-zone/exnaton/exnaton.service';

@Component({
  selector: 'app-no-price-exnaton',
  standalone: true,
  imports: [CommonModule, ErrorAccessComponent, SitesSwitcherComponent],
  templateUrl: './no-price-exnaton.component.html',
  styleUrls: ['./no-price-exnaton.component.scss'],
})
export class NoPriceExnatonComponent {
  errorAccessConfig: ErrorAccessConfig = {
    title: 'pages.dynamicTariff.currentPricesNotLoadedAlert.title',
    content: 'pages.dynamicTariff.currentPricesNotLoadedAlert.content',
    ctaLink: 'pages.dynamicTariff.currentPricesNotLoadedAlert.ctaLink',
    ctaLabel: 'pages.dynamicTariff.currentPricesNotLoadedAlert.ctaLabel',
    navigateExternalSite: true,
  };

  sites$: Observable<Site[]>;
  activeSite: Site;
  activeReference: string;
  activeSiteId: string;
  versionSize = VersionSize;

  constructor(private facade: MainFacade, private destroyRef: DestroyRef, private exnatonService: ExnatonService) {}

  ngOnInit(): void {
    this.facade.activeSite$
      .pipe(
        tap((site: Site) => {
          this.activeReference = this.facade.state$.value.reference;
          this.sites$ = this.facade.sites$;
          this.activeSiteId = site?.id;
          this.activeSite = site;
        }),
        skip(1), // We don't want to redirect user to dashobard on load of the page, but only when user changes site/ref
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(() => {
        this.reloadConsumptionPage();
      });
  }

  switchSite(siteId: string) {
    this.facade.updateActiveSite(siteId);
  }

  reloadConsumptionPage() {
    this.facade.utils.redirectTo('/consumptions/');
  }
}

_('pages.dynamicTariff.currentPricesNotLoadedAlert.title');
_('pages.dynamicTariff.currentPricesNotLoadedAlert.content');
_('pages.dynamicTariff.currentPricesNotLoadedAlert.ctaLink');
_('pages.dynamicTariff.currentPricesNotLoadedAlert.ctaLabel');

<div class="card-primary-small mb-4" *ngIf="meteringsError">
  {{ 'errorMessages.serverError' | translate }}
</div>

<div *ngIf="meteringsArray[contract?.deliveryPointReference] | async as meterings">
  <div class="card-primary-small mb-4" *ngIf="meterings.length === 0 && !meteringsError">
    {{ 'general.noIndex' | translate }}
  </div>

  <div *ngFor="let m of meterings; let i = index" class="metering-table" [class.has-metering]="m[0].to?.indexes">
    <div class="{{ !m[0].to?.indexes ? 'card-primary-small' : 'official-meter-wrapper' }}">
      <h6 *ngIf="!m[0].to?.indexes" class="text-base text-grey-dark60 text-primary-500 mb-8 inline-block w-full">
        {{ 'components.meterReadingTable.currentIndexes' | translate }}
      </h6>
      <h6 *ngIf="m[0].to?.indexes" class="previous-metering text-base text-grey-dark60 mb-8 inline-block w-full">
        {{ 'components.meterReadingTable.previousIndexes' | translate }}
      </h6>
      <div class="flow-root mb-4 inline-block w-full">
        <span class="font-medium block sm:float-left lg:text-xl">
          {{ m[0].from.indexDate | date : 'MMMM yyyy' : undefined : translateService.currentLang + '-BE' | titlecase }}
          -
          {{
            m[0].to
              ? (m[0].to?.indexDate | date : 'MMMM yyyy' : undefined : translateService.currentLang + '-BE' | titlecase)
              : '?'
          }}
        </span>
        <span class="block text-sm sm:float-right sm:mt-2" *ngIf="m[0].period">
          {{ 'components.meterReadingTable.period' | translate : { period: m[0].period } }}
        </span>
      </div>

      <ng-container *ngFor="let metering of m; let j = index">
        <ng-container *ngIf="metering !== null">
          <h5 *ngIf="j === 0 && m[1] !== null" class="mb-4 font-medium">
            <img
              src="./assets/img/icons/standalone/red/conso.svg"
              class="inline-block w-5 mr-2"
              style="vertical-align: -0.3rem"
              alt=""
            />
            {{ 'general.consumption' | translate }}
          </h5>
          <h5 *ngIf="j === 1" class="mt-8 mb-4 font-medium">
            <img
              src="./assets/img/icons/standalone/red/prod.svg"
              class="inline-block w-8 mr-2"
              style="vertical-align: -0.3rem"
              alt=""
            />
            {{ 'general.production' | translate }}
          </h5>
          <table>
            <thead class="hidden lg:table-header-group">
              <tr class="inline-block w-full lg:table-row">
                <th scope="col">{{ 'general.meterType.title' | translate }}</th>
                <th class="text-center p-0" scope="col">
                  {{ 'components.meterReadingTable.firstIndex' | translate }}
                  <small class="block text-base font-bold text-grey-dark">
                    {{ metering.from.indexDate | date : 'longDate' : undefined : translateService.currentLang + '-BE' }}
                  </small>
                </th>
                <th class="text-center" scope="col">
                  {{ 'components.meterReadingTable.otherIndexes' | translate }}
                </th>
                <th class="text-center p-0" scope="col">
                  {{ 'components.meterReadingTable.lastIndex' | translate }}
                  <small class="block text-base font-bold text-grey-dark">
                    {{ metering.to?.indexDate | date : 'longDate' : undefined : translateService.currentLang + '-BE' }}
                  </small>
                </th>
                <th class="text-center" scope="col">
                  {{ 'general.' + (j === 0 ? 'consumption' : 'injection') | translate }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr class="hidden w-full lg:table-cell">
                <td *ngFor="let indexes of metering.from.indexes" class="block">
                  <span class="meter-label {{ indexes.register | lowercase }}">
                    {{ 'general.meters.' + indexes.register + '.title' | translate }}
                  </span>
                </td>
              </tr>

              <tr class="inline-block w-full lg:table-cell">
                <td
                  class="mobile-title block lg:hidden"
                  [attr.data-label]="
                    metering.from.indexDate | date : 'longDate' : undefined : translateService.currentLang + '-BE'
                  "
                ></td>
                <td
                  *ngFor="let indexes of metering.from.indexes"
                  [attr.data-label]="'general.meters.' + indexes.register + '.title' | translate"
                  class="text-right lg:text-center block"
                >
                  {{ indexes.value | number : '1.1-2' }} {{ 'general.units.' + energyUnit | translate }}
                </td>
              </tr>

              <tr
                *ngIf="provisionalIndexesArray[contract.deliveryPointReference] | async as provisionalIndexes"
                class="inline-block w-full relative lg:table-cell"
              >
                <td class="mobile-title block lg:hidden" data-label="Intermédiaire"></td>
                <td class="provisional">
                  <span
                    class="font-medium lg:inline-block lg:w-full lg:text-center"
                    *ngIf="
                      !provisionalIndexes ||
                      !provisionalIndexes[i] ||
                      provisionalIndexes[i].length === 0 ||
                      !provisionalIndexes[i][j] ||
                      provisionalIndexes[i][j].length === 0
                    "
                  >
                    {{ 'components.meterReadingTable.noProvisionalIndex' | translate }}
                  </span>
                  <span
                    *ngIf="
                      provisionalIndexes &&
                      provisionalIndexes[i] &&
                      provisionalIndexes[i].length > 0 &&
                      provisionalIndexes[i][j] &&
                      provisionalIndexes[i][j].length > 0
                    "
                    class="font-medium text-primary cursor-pointer lg:inline-block lg:w-full lg:text-center"
                    (click)="showProvisionalIndexes(provisionalIndexes[i][j], metering, provisionalIndexes[i])"
                  >
                    {{ 'components.meterReadingTable.allIndexes' | translate }}
                  </span>
                </td>
              </tr>

              <tr class="{{ !metering.to?.indexes ? 'hidden w-full lg:table-cell' : 'w-full lg:table-cell' }}">
                <ng-container *ngIf="metering.to?.indexes; else empty">
                  <td
                    class="mobile-title block lg:hidden"
                    [attr.data-label]="
                      metering.to?.indexDate | date : 'longDate' : undefined : translateService.currentLang + '-BE'
                    "
                  ></td>
                  <td
                    *ngFor="let indexes of metering.to?.indexes"
                    [attr.data-label]="'general.meters.' + indexes.register + '.title' | translate"
                    class="text-right lg:text-center block"
                  >
                    {{ indexes.value | number : '1.1-2' }} {{ 'general.units.' + energyUnit | translate }}
                  </td>
                </ng-container>
                <ng-template #empty>
                  <td class="hidden"></td>
                  <td
                    *ngFor="let indexes of metering.from.indexes"
                    [attr.data-label]="'general.meters.' + indexes.register + '.title' | translate"
                    class="block"
                  >
                    &nbsp;
                  </td>
                </ng-template>
              </tr>

              <tr class="{{ !metering.total?.length ? 'hidden w-full lg:table-cell' : 'w-full lg:table-cell' }}">
                <ng-container *ngIf="metering.total?.length; else noConsumption">
                  <td
                    class="mobile-title block lg:hidden"
                    [attr.data-label]="'general.' + (j === 0 ? 'consumption' : 'injection') | translate"
                  ></td>
                  <td
                    *ngFor="let consumption of metering.total"
                    [attr.data-label]="'general.meters.' + consumption.register + '.title' | translate"
                    class="text-right lg:text-center block"
                  >
                    {{ consumption.value | number : '1.1-2' }} {{ 'general.units.' + energyUnit | translate }}
                  </td>
                </ng-container>
                <ng-template #noConsumption>
                  <td class="hidden"></td>
                  <td
                    class="block"
                    *ngFor="let indexes of metering.from.indexes"
                    [attr.data-label]="'general.meters.' + indexes.register + '.title' | translate"
                  >
                    &nbsp;
                  </td>
                </ng-template>
              </tr>
            </tbody>
          </table>
        </ng-container>
      </ng-container>
    </div>
  </div>
</div>

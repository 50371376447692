import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { myEnergyEvents } from '@app/core/analytics/models/event-defaults';
import { AnalyticsService } from '@app/shared/modules/analytics/services/analytics.service';
import { TranslateService } from '@ngx-translate/core';
import { IndexModalService } from '@app/modules/customer-zone/consumption/services/index-modal/index-modal.service';
import { IndexEncodingOrigin } from '@app/modules/customer-zone/consumption/models/consumption.interface';

@Component({
  selector: 'if-intro',
  templateUrl: './if-intro.component.html',
  styleUrls: ['./if-intro.component.scss'],
})
export class IfIntroComponent implements OnInit {
  @Input() origin?: IndexEncodingOrigin = IndexEncodingOrigin.default;
  @Input() canChangeInstallmentIntro: boolean;
  @Output() initSteps: EventEmitter<any> = new EventEmitter<any>();
  protected indexEncodingOrigin = IndexEncodingOrigin;

  constructor(
    private analyticsService: AnalyticsService,
    private translateService: TranslateService,
    private indexModalService: IndexModalService
  ) {}

  ngOnInit(): void {
    this.analytics();
    if (!this.canChangeInstallmentIntro) {
      this.indexModalService.setModalTitle(this.translateService.instant('components.consumption.introduction.title'));
    }
    if (this.origin == 'ENERGY_INSIGHT') {
      this.indexModalService.setModalTitle(
        this.translateService.instant('components.consumption.introductionEnergyInsight.title')
      );
    }
  }

  next() {
    this.initSteps.emit();
  }

  private analytics() {
    this.analyticsService.push({
      ...myEnergyEvents(`${this.translateService.currentLang}/myenergy-flow/introduction`),
    });
  }
}

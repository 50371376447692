<!-- If we get current advances -->
<div *ngIf="!formSubmitted; else formSubmittedMessage">
  <ng-container *ngIf="advances$ | async as advances; else advancesLoading">
    <ng-container *ngFor="let advance of advances">
      <div *ngIf="advance.deliveryPoint === contract.deliveryPointReference">
        <div class="update-monthly-installment">
          <container-element [ngSwitch]="useCaseMyEnergy">
            <ng-container
              *ngSwitchCase="UseCaseMyEnergyType.DECREASE_POSSIBLE"
              [ngTemplateOutlet]="showMonthlyInstallmentForm"
            >
            </ng-container>

            <ng-container
              *ngSwitchCase="UseCaseMyEnergyType.INCREASE_RECOMMENDED_AND_DECREASE_NOT_POSSIBLE"
              [ngTemplateOutlet]="showMonthlyInstallmentForm"
            >
            </ng-container>

            <ng-container
              *ngSwitchCase="UseCaseMyEnergyType.INCREASE_WITH_DECREASE_POSSIBLE"
              [ngTemplateOutlet]="showMonthlyInstallmentForm"
            >
            </ng-container>

            <ng-container *ngSwitchCase="UseCaseMyEnergyType.DECREASE_TOO_IMPORTANT">
              <app-warning-message
                class="block lg:w-[50rem]"
                [contract]="contract"
                [messageType]="UseCaseMyEnergyType.DECREASE_TOO_IMPORTANT"
                (formReady)="continue()"
              >
              </app-warning-message>
            </ng-container>

            <ng-container *ngSwitchCase="UseCaseMyEnergyType.MODIFICATION_ONGOING">
              <app-warning-message
                class="block lg:w-[50rem]"
                [contract]="contract"
                [messageType]="UseCaseMyEnergyType.MODIFICATION_ONGOING"
                (formReady)="continue()"
              >
              </app-warning-message>
            </ng-container>

            <ng-container *ngSwitchCase="UseCaseMyEnergyType.ALREADY_DECREASED">
              <app-warning-message
                class="block lg:w-[50rem]"
                [contract]="contract"
                [messageType]="UseCaseMyEnergyType.ALREADY_DECREASED"
                (formReady)="continue()"
              >
              </app-warning-message>
            </ng-container>
            <ng-container *ngSwitchCase="UseCaseMyEnergyType.ALREADY_OK">
              <app-already-ok-message class="block lg:w-[42rem]" (formReady)="continue()"> </app-already-ok-message>
            </ng-container>
          </container-element>
        </div>
      </div>
    </ng-container>
  </ng-container>
</div>

<!-- Monthly Installment full form -->
<ng-template #showMonthlyInstallmentForm>
  <app-monthly-installment-form
    [contract]="contract"
    [energy]="energy"
    [control]="control"
    [minAmount]="minAmount"
    [suggestedAmount]="suggestedAmount"
    [useCaseMyEnergy]="useCaseMyEnergy"
    (amountEmitter)="submit($event)"
    (formReady)="continue()"
  >
  </app-monthly-installment-form>
</ng-template>
<!-- -->

<!-- If loading or not able to get current advances -->
<ng-template #advancesLoading>
  <div class="loader-container">
    <app-loader></app-loader>
  </div>
</ng-template>
<!-- -->

<!-- Form has been submitted -->
<ng-template #formSubmittedMessage>
  <if-update-success
    [contract]="contract"
    [energy]="energy"
    [previousAmount]="currentAmount"
    [newAmount]="control.value"
    (formReady)="continue()"
  >
  </if-update-success>
</ng-template>
<!-- -->

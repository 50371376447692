<ng-container *ngIf="relatedInvoices.length < MAX_INVOICES; else modalDisplay">
  <div>
    <span class="inline-block font-medium text-grey-dark60 text-sm">
      {{
        'components.paymentPlan.overview.paymentPlanDetails.relatedInvoices.content.' +
          (relatedInvoices.length > 1 ? 'multi' : 'single') | translate
      }}
    </span>
    <ul class="inline-block text-grey-dark60 text-sm">
      <li *ngFor="let invoices of relatedInvoices" class="inline-block first:pl-0 md:first:pl-4 pl-4">
        {{ invoices }}
      </li>
    </ul>
  </div>
</ng-container>
<ng-template #modalDisplay>
  <span (click)="openModal(content)" class="btn-text inline-block w-full text-primary font-medium cursor-pointer">
    {{
      'components.paymentPlan.overview.paymentPlanDetails.relatedInvoices.cta'
        | translate : { nbInvoices: relatedInvoices.length }
    }}
  </span>
  <ng-template #content let-modal>
    <app-modal
      [customHeaderClass]="'bg-grey-light'"
      [activeModal]="modal"
      title="{{ 'components.paymentPlan.overview.paymentPlanDetails.relatedInvoices.title' | translate }}"
    >
      <ul class="text-lg">
        <li
          *ngFor="let invoices of relatedInvoices"
          class="inline-block w-full border-b border-grey pb-6 mb-6 last:border-0 last:pb-0"
        >
          {{ invoices }}
        </li>
      </ul>
    </app-modal>
  </ng-template>
</ng-template>

<div class="px-8 xl:max-w-screen-xl xl:w-full xl:m-auto">
  <div class="mb-16">
    <h2 class="mt-12 mb-8 font-display font-normal text-5xl te-text-gradient-home inline-block md:my-12">
      {{ 'pages.paymentPreferences.title' | translate }}
    </h2>
    <div class="grid grid-cols-1 md:grid-cols-12 gap-16">
      <div class="md:col-span-8" *ngIf="viewModel$ | async as viewModel">
        <app-payment-method
          [billingDetails]="viewModel?.billingDetails"
          [contactDetails]="viewModel?.contactDetails"
          [reference]="activeReference"
        >
        </app-payment-method>

        <app-refund-method id="refund" [billingDetails]="viewModel?.billingDetails" [reference]="activeReference">
        </app-refund-method>

        <app-invoice-delivery-channel
          [billingDetails]="viewModel?.billingDetails"
          [contactDetails]="viewModel?.contactDetails"
          [reference]="activeReference"
        >
        </app-invoice-delivery-channel>
      </div>
      <div class="md:col-span-4">
        <app-chat-cta></app-chat-cta>
      </div>
    </div>
  </div>
</div>

<ng-container *ngIf="!isMobile">
  <ul class="nav-tabs tabs tabs--small">
    <ng-container *ngFor="let option of options">
      <li
        [analyticsEvent]="'switch:' + option + ':click'"
        (click)="active !== option && switch(option)"
        class="tab__item"
        [class.active]="active === option"
      >
        <span>
          {{ translationPrefix + (option | lowercase) | translate }}
        </span>
      </li>
    </ng-container>
  </ul>
</ng-container>

<div class="mobile" *ngIf="isMobile">
  <ul class="nav-tabs--mobile">
    <ng-container *ngFor="let option of options">
      <li
        [analyticsEvent]="'switch:' + option + ':click'"
        (click)="active !== option && switch(option)"
        class="tab__item--mobile"
        [class.active]="active === option"
      >
        <div class="icon"></div>
        <span>
          {{ translationPrefix + (option | lowercase) | translate }}
        </span>
      </li>
    </ng-container>
  </ul>
</div>

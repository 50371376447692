<div *ngIf="origin === indexEncodingOrigin.energyInsight" class="sm:mt-4">
  <h4>{{ 'components.consumption.success.indexPostedEnergyInsight.title' | translate }}</h4>
  <p
    class="sm:mt-6"
    style="line-height: 180%"
    [innerHTML]="'components.consumption.success.indexPostedEnergyInsight.content' | translate"
  ></p>
  <button (click)="continue()" class="btn btn-primary font-bold inline-block w-full sm:w-auto mt-8">
    {{ 'general.understand' | translate }}
  </button>
</div>

<div *ngIf="origin !== indexEncodingOrigin.energyInsight" class="sm:mt-4">
  <h4>{{ 'components.consumption.success.' + type + '.title' | translate }}</h4>
  <p
    class="sm:mt-6"
    style="line-height: 180%"
    [innerHTML]="'components.consumption.success.' + type + '.content' | translate"
  ></p>
  <button (click)="continue()" class="btn btn-primary font-bold inline-block w-full sm:w-auto mt-8">
    {{ 'general.understand' | translate }}
  </button>
</div>

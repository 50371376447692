<div class="step-wrapper">
  <div class="step mb-16">
    <h2 class="mb-8 font-sans font-medium text-3xl te-text-gradient-home inline-block w-full">
      {{ 'pages.move.newAddress.title' | translate }}
    </h2>
    <span>
      {{ 'pages.move.newAddress.subtitle' | translate }}
    </span>

    <div [formGroup]="form">
      <section class="relative my-10">
        <app-energy-type [deliveryPoints]="moveOutDeliveryPoints"> </app-energy-type>
      </section>

      <section>
        <h3 class="font-medium text-2xl mt-10">
          {{ 'pages.move.address.newAddressTitle' | translate }}
        </h3>
        <app-move-address formGroupName="newAddress" [submittedForm]="submittedForm"> </app-move-address>
      </section>

      <section class="mt-8">
        <h3 class="text-2xl font-medium text-grey-dark mt-12">
          {{ 'pages.move.newAddress.contract.title' | translate }}
        </h3>
        <h4 class="text-base font-medium mt-4 mb-2">
          {{ 'pages.move.newAddress.contract.explanation' | translate }}
        </h4>
        <p class="text-sm">
          {{ 'pages.move.newAddress.contract.additionalExplanation' | translate }}
        </p>
        <a
          [href]="'pages.move.newAddress.moreInfo.link' | translate"
          target="_blank"
          class="text-primary font-bold mt-4 text-sm inline-block w-full"
        >
          {{ 'pages.move.newAddress.moreInfo.label' | translate }}</a
        >
      </section>

      <section formGroupName="invoicingData">
        <app-invoicing-method
          [billingEmail]="billingEmail"
          [submittedForm]="submittedForm"
          [defaultInvoiceDeliveryChannel]="defaultInvoiceDeliveryChannel"
        ></app-invoicing-method>
      </section>
    </div>
  </div>
  <app-navigation [stepValidity]="true" (nextClick)="onNextClicked()" (previousClick)="onPreviousClicked()">
  </app-navigation>
</div>

<div>
  <h3 class="text-3xl mb-8">
    {{ 'components.paymentPlan.progress.title' | translate }}
  </h3>
  <div class="bg-grey-light rounded-4xl p-8">
    <span class="inline-block w-full font-medium pp-ongoing">
      {{ 'components.paymentPlan.progress.status' | translate }}
    </span>
  </div>

  <div class="circle-wrapper">
    <div
      class="circle-progress"
      style="background: radial-gradient(white 60%, transparent 60%), conic-gradient(from 0deg,#40a900 0%,#00884a {{
        progress
      }}%, rgb(247 249 250) {{ progress }}%)"
    ></div>
    <div class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-center">
      <div class="text-4xl font-medium">
        <span>{{ paymentPlan?.paidSlices }}</span>
        <span class="text-grey">/{{ paymentPlan?.totalSlices }}</span>
      </div>
      <span class="inline-block text-sm mt-4">
        {{ 'components.paymentPlan.progress.description.' + progressKey | translate }}
      </span>
    </div>
  </div>
  <div class="mt-8 text-center text-grey-dark60">
    {{ 'components.paymentPlan.progress.balance' | translate }}
    <span class="text-grey-dark font-medium">
      {{ paymentPlan?.balance | currency : 'EUR' }}
    </span>
  </div>
</div>

<ng-container [ngSwitch]="advice?.status">
  <div class="sm:flex gap-8 mt-8" [class.flex-col]="viewType === viewTypes.list">
    <ng-container *ngSwitchCase="adviceStatus.none">
      <div
        class="flex-1 rounded-2xl bg-grey-light p-4 box-shadow-small border border-white cursor-pointer mb-4 sm:mb-0"
        (click)="updateStatus(adviceStatus.implemented)"
      >
        <div class="flex">
          <span class="text-primary font-medium">{{ 'components.advice.status.implemented' | translate }}</span>
          <img
            ngSrc="./assets/img/icons/standalone/dark-grey/done.svg"
            class="ml-auto inline-block"
            alt=""
            height="18"
            width="18"
          />
        </div>
        <span class="text-sm">{{ 'components.advice.general.tipDone' | translate }}</span>
      </div>
      <div
        class="flex-1 rounded-2xl bg-grey-light p-4 box-shadow-small border border-white cursor-pointer"
        (click)="updateStatus(adviceStatus.skipped)"
      >
        <div class="flex">
          <span class="text-primary font-medium">{{ 'components.advice.status.skipped' | translate }}</span>
          <img
            ngSrc="./assets/img/icons/standalone/dark-grey/archive.svg"
            class="ml-auto inline-block"
            alt=""
            height="18"
            width="18"
          />
        </div>
        <span class="text-sm">{{ 'components.advice.general.tipNotApplicable' | translate }}</span>
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="adviceStatus.implemented">
      <div
        class="flex-1 rounded-2xl bg-grey-light p-4 box-shadow-small border border-white cursor-pointer"
        (click)="updateStatus(adviceStatus.none)"
      >
        <div class="flex">
          <span class="text-primary font-medium">{{ 'components.advice.general.resetTip' | translate }}</span>
          <img
            ngSrc="./assets/img/icons/standalone/dark-grey/undo.svg"
            class="ml-auto inline-block"
            alt=""
            height="18"
            width="18"
          />
        </div>
        <span class="text-sm">{{ 'components.advice.general.markAsTodo' | translate }}</span>
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="adviceStatus.skipped">
      <div
        class="flex-1 rounded-2xl bg-grey-light p-4 box-shadow-small border border-white cursor-pointer"
        (click)="updateStatus(adviceStatus.none)"
      >
        <div class="flex">
          <span class="text-primary font-medium">{{ 'components.advice.general.resetTip' | translate }}</span>
          <img
            ngSrc="./assets/img/icons/standalone/dark-grey/undo.svg"
            class="ml-auto inline-block"
            alt=""
            height="18"
            width="18"
          />
        </div>
        <span class="text-sm">{{ 'components.advice.general.markAsTodo' | translate }}</span>
      </div>
    </ng-container>
  </div>
</ng-container>

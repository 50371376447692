<div *ngIf="visibility" class="w-full bg-gradient-to-b sm:bg-gradient-to-r from-primary to-orange">
  <div class="bg-[url('./assets/img/giveaway-banner.png')] bg-no-repeat bg-cover bg-center">
    <div class="p-10 xl:px-0 sm:flex sm:justify-between sm:items-center xl:max-w-screen-xl xl:w-full xl:m-auto">
      <div class="flex flex-row-reverse sm:flex-row items-center gap-x-8">
        <img
          ngSrc="./assets/img/giveaway-banner-gift.svg"
          alt="gift"
          height="154"
          width="153"
          class="hidden sm:block"
        />
        <div>
          <div class="flex">
            <div class="pb-8 text-white">
              <div class="hidden sm:block">
                <div
                  class="text-4xl font-bold"
                  [innerHTML]="'components.banners.giveaway.desktop.title' | translate"
                ></div>
                <div
                  class="text-xl font-medium"
                  [innerHTML]="'components.banners.giveaway.desktop.subtitle' | translate"
                ></div>
              </div>
              <div class="block sm:hidden">
                <div
                  class="text-4xl font-bold"
                  [innerHTML]="'components.banners.giveaway.mobile.title' | translate"
                ></div>
                <div class="font-medium" [innerHTML]="'components.banners.giveaway.mobile.subtitle' | translate"></div>
              </div>
            </div>
            <img
              ngSrc="./assets/img/giveaway-banner-gift.svg"
              alt="gift"
              height="113"
              width="112"
              class="block sm:hidden self-start"
            />
          </div>
          <div class="md:flex items-center px-6 py-4 text-white border border-solid border-white rounded-2xl text-base">
            <div class="pb-4 md:pb-0 sm:pr-4 leading-8">
              <div
                class="font-medium hidden sm:block"
                [innerHTML]="'components.banners.giveaway.desktop.explanation' | translate"
              ></div>
              <div
                class="font-medium block sm:hidden"
                [innerHTML]="'components.banners.giveaway.mobile.explanation' | translate"
              ></div>
            </div>
            <div class="flex gap-x-4">
              <a
                href="https://apps.apple.com/be/app/totalenergies-power-gas/id1601217446"
                target="_blank"
                [analyticsEvent]="'giveawayBanner:quick-actions:christmas-contest-apple'"
              >
                <img
                  ngSrc="./assets/img/button-apple-store.svg"
                  height="60"
                  width="204"
                  alt="apple store"
                  class="h-16"
                />
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=be.totalenergies.mytotalenergies"
                target="_blank"
                [analyticsEvent]="'giveawayBanner:quick-actions:christmas-contest-google'"
              >
                <img
                  ngSrc="./assets/img/button-google-play.svg"
                  height="60"
                  width="204"
                  alt="google play"
                  class="h-16"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="sites-infos bg-white">
  <div class="px-8 xl:max-w-screen-xl xl:w-full xl:m-auto">
    <div class="items-center pb-8 pt-14 sm:pb-14 md:grid md:grid-cols-12 md:gap-16">
      <div class="mb-12 md:mb-0 md:col-span-8">
        <app-sites-switcher
          class="inline-block w-full ml-auto sites-switcher mb-8 md:mb-0 md:w-auto"
          [sites]="sites$ | async"
          [activeSite]="activeSite"
          [version]="versionSize.big"
          [activeReference]="activeReference"
          (switchSiteEmitter)="switchSite($event)"
        >
        </app-sites-switcher>
      </div>
    </div>
  </div>
</div>
<div class="px-8 xl:max-w-screen-xl xl:w-full xl:m-auto" *ngIf="(scriptLoaded$ | async) !== null; else loading">
  <div class="mb-16">
    <div class="md:grid md:grid-cols-12 gap-16">
      <div class="md:col-span-12 z-10">
        <div class="content">
          <h3 class="text-4xl font-normal te-text-gradient-home mb-12 mt-20 w-fit">
            {{ 'pages.dynamicTariff.overview' | translate }}
          </h3>

          <section class="grid grid-cols-1 md:grid-cols-4 lg:grid-cols-3 gap-10">
            <div class="col-span-1 md:col-span-2 lg:col-span-1">
              <app-exnaton-molecule [exnatonType]="exnatonMoleculeType.comparison"></app-exnaton-molecule>
            </div>

            <div class="col-span-1 md:col-span-2">
              <app-exnaton-molecule
                [exnatonType]="exnatonMoleculeType.carouselCard"
                [tipItems]="tipItems"
              ></app-exnaton-molecule>
            </div>
          </section>

          <section class="grid grid-cols-1 md:grid-cols-4 lg:grid-cols-3 gap-10 mt-10">
            <div class="col-span-1 md:col-span-2 lg:col-span-1">
              <app-exnaton-molecule
                [exnatonType]="exnatonMoleculeType.currentPrice"
                [tooltip]="'pages.dynamicTariff.tooltip.currentPrice' | translate"
              ></app-exnaton-molecule>
            </div>
            <div class="col-span-1 md:col-span-2">
              <app-exnaton-molecule
                [exnatonType]="exnatonMoleculeType.priceChart"
                setUpErrorCalback="true"
                [tooltip]="'pages.dynamicTariff.tooltip.priceChart' | translate"
              ></app-exnaton-molecule>
            </div>
          </section>

          <section class="grid grid-cols-1 md:grid-cols-3 gap-10 mt-10">
            <div class="col-span-1">
              <app-exnaton-molecule [exnatonType]="exnatonMoleculeType.kpiCard"></app-exnaton-molecule>
            </div>
            <div class="col-span-1">
              <app-exnaton-molecule
                [exnatonType]="exnatonMoleculeType.kpiCard"
                [exnatonField]="exnatonMoleculeField.accountTotalPriceForConsumption"
                [tooltip]="'pages.dynamicTariff.tooltip.accountTotalPriceForConsumption' | translate"
              ></app-exnaton-molecule>
            </div>
            <div class="col-span-1">
              <app-exnaton-molecule
                [exnatonType]="exnatonMoleculeType.kpiCard"
                [exnatonField]="exnatonMoleculeField.accountAveragePriceForConsumption"
                [tooltip]="'pages.dynamicTariff.tooltip.accountAveragePriceForConsumption' | translate"
              ></app-exnaton-molecule>
            </div>
          </section>

          <section class="grid grid-cols-1 md:grid-cols-3 gap-10 mt-10" *ngIf="accessInjection">
            <div class="md:col-span-1">
              <app-exnaton-molecule
                [exnatonType]="exnatonMoleculeType.kpiCard"
                [exnatonField]="exnatonMoleculeField.accountTotalEnergyProduction"
                [tooltip]="'pages.dynamicTariff.tooltip.accountTotalEnergyProduction' | translate"
              ></app-exnaton-molecule>
            </div>
            <div class="md:col-span-1">
              <app-exnaton-molecule
                [exnatonType]="exnatonMoleculeType.kpiCard"
                [exnatonField]="exnatonMoleculeField.accountTotalPriceForProduction"
              ></app-exnaton-molecule>
            </div>
            <div class="md:col-span-1"></div>
          </section>

          <h3 class="text-4xl font-normal te-text-gradient-home mb-12 mt-20 w-fit">
            {{ 'pages.dynamicTariff.consumption' | translate }}
          </h3>

          <div class="md:h-full border border-solid border-gray-300 bg-white rounded-4xl mb-12">
            <div class="m-8">
              <app-exnaton-molecule
                [exnatonType]="exnatonMoleculeType.datePicker"
                [disableBorder]="true"
              ></app-exnaton-molecule>
            </div>
            <app-exnaton-molecule
              [exnatonType]="exnatonMoleculeType.energyChart"
              [showProduction]="accessInjection"
              [tooltip]="'pages.dynamicTariff.tooltip.energyChart' | translate"
              [disableBorder]="true"
            ></app-exnaton-molecule>
            <app-exnaton-molecule
              [exnatonType]="exnatonMoleculeType.financeChart"
              [showProduction]="accessInjection"
              [tooltip]="'pages.dynamicTariff.tooltip.financeChart' | translate"
              [disableBorder]="true"
            ></app-exnaton-molecule>
          </div>

          <h3 class="text-4xl font-normal te-text-gradient-home mb-12 mt-20 w-fit">
            {{ 'pages.dynamicTariff.export.title' | translate }}
          </h3>

          <div class="mb-12">
            <app-exnaton-molecule [exnatonType]="exnatonMoleculeType.detailedReport">
              <div class="m-8 flex justify-start gap-4">
                <strong>{{ 'pages.dynamicTariff.export.subtitle' | translate }}</strong>
                <app-tooltip [tooltip]="'pages.dynamicTariff.export.tooltip' | translate"></app-tooltip>
              </div>
            </app-exnaton-molecule>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #loading>
  <app-loader-widget [showBorder]="false"></app-loader-widget>
</ng-template>

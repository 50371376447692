<div>
  <app-fluvius-mandates-intro
    *ngIf="!eliqAccessRights?.accessPerSiteId?.isEnergyInsightFeatureEligible"
    [mandates]="mandates"
    [eliqAccessRights]="eliqAccessRights"
  >
  </app-fluvius-mandates-intro>
  <app-fluvius-mandates [mandates]="mandates" [reference]="reference" [eliqAccessRights]="eliqAccessRights">
  </app-fluvius-mandates>
</div>

<div *ngIf="eliqAccessRights$ | async as eliqAccessRights">
  <div class="sites-infos bg-white">
    <div class="px-8 xl:max-w-screen-xl xl:w-full xl:m-auto">
      <div class="items-center pb-8 pt-14 sm:pb-14 md:grid md:grid-cols-12 md:gap-16">
        <div class="md:col-span-8">
          <app-sites-switcher
            class="inline-block w-full ml-auto sites-switcher md:w-auto"
            [sites]="sites$ | async"
            [activeSite]="activeSite"
            [version]="versionSize.big"
            [activeReference]="reference"
            (switchSiteEmitter)="switchSite($event)"
          >
          </app-sites-switcher>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="eliqAccessRights?.accessPerSiteId?.isEnergyInsightFeatureEligible; else mandatesView">
    <div class="px-8 xl:max-w-screen-xl xl:w-full xl:m-auto">
      <ng-container>
        <div class="my-16">
          <div class="grid grid-cols-1 md:grid-cols-12 gap-16">
            <div class="md:col-span-8">
              <div class="card-primary mb-8">
                <div *ngIf="activeSite" class="mb-8 pb-8 md:mb-16 md:pb-12 border-b border-grey">
                  <div class="md:flex flex-row justify-between items-center">
                    <div class="md:mr-12">
                      <h3>{{ 'components.homeProfileForm.title.label' | translate }}</h3>
                      <div
                        class="mt-4 font-medium text-grey-dark60 text-base"
                        [innerHTML]="'components.homeProfileForm.description.label' | translate"
                      ></div>
                    </div>
                    <div
                      class="mt-4 md:mt-0 basis-3/5"
                      *ngIf="
                        eliqAccessRights?.accessPerSiteId?.isEnergyInsightFeatureEligible &&
                        eliqAccessRights?.accessPerSiteId?.seeHomeProfileTabAndMyHome
                      "
                    >
                      <div
                        *ngIf="(homeProfileCompletion$ | async) || (homeProfile$ | async) as homeProfile"
                        class="bg-grey-light border border-grey rounded-2xl pt-6 pb-8 px-8"
                      >
                        <div *ngIf="homeProfile?.completion !== null">
                          <span class="flex justify-between font-bold text-grey-dark text-[1.3rem] pb-4">
                            <span>{{ 'components.homeProfile.sidebar.progress.progressBar' | translate }}</span>
                            <span>{{ homeProfile?.completion / 100 | percent }}</span>
                          </span>
                          <app-progress-bar [progressValue]="homeProfile?.completion"></app-progress-bar>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <app-home-profile-form
                  [homeProfile]="(homeProfile$ | async)?.data"
                  [updateOngoing]="updateOngoing"
                  (homeProfileUpdateEmitter)="updateHomeProfile($event)"
                >
                </app-home-profile-form>
              </div>
            </div>
            <div class="md:col-span-4">
              <app-e-move></app-e-move>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-template #eliqIsSyncData>
        <app-eliq-sync-in-progress> </app-eliq-sync-in-progress>
      </ng-template>
    </div>
  </div>

  <ng-template #mandatesView>
    <app-fluvius-mandates-intro [mandates]="mandates$ | async" [eliqAccessRights]="eliqAccessRights">
    </app-fluvius-mandates-intro>
    <app-fluvius-mandates [mandates]="mandates$ | async" [reference]="reference" [eliqAccessRights]="eliqAccessRights">
    </app-fluvius-mandates>
  </ng-template>
</div>

<app-success-popup [showPopup]="successPopup" (closeEmitter)="closeSuccessPopup()">
  <ng-container title>
    {{ 'components.homeProfile.completed.title' | translate }}
  </ng-container>
  <ng-container content>
    <div class="text-center w-full">
      <img src="./assets/img/guy-le-pottier.png" class="w-full max-w-lg mx-auto" />
      <p class="my-8">
        {{ 'components.homeProfile.completed.content' | translate }}
      </p>
      <a
        class="btn btn-primary text-center w-auto"
        [routerLink]="['/consumptions']"
        [innerHTML]="'components.homeProfile.completed.cta' | translate"
      >
      </a>
    </div>
  </ng-container>
</app-success-popup>

<ng-container *ngIf="infoBoxTranslations">
  <div class="sm:block sm:mt-10" *ngFor="let infoBox of infoBoxTranslations | async">
    <div class="card te-border-gradient-home">
      <div class="article">
        <img ngSrc="./assets/img/mobile-app.png" class="w-full mb-10" alt="Mobile App" height="170" width="281" />
        <h3
          class="text-lg te-text-gradient-home font-medium mb-6 leading-tight"
          [innerHTML]="infoBox?.title | translate"
        ></h3>
        <p class="text-sm text-grey-dark" [innerHTML]="infoBox?.content | translate"></p>
        <div class="flex gap-1 justify-between mt-8">
          <a href="https://apps.apple.com/be/app/totalenergies-power-gas/id1601217446" target="_blank">
            <img ngSrc="./assets/img/button-apple-store.svg" class="h-16" alt="App Store" height="40" width="135" />
          </a>
          <a href="https://play.google.com/store/apps/details?id=be.totalenergies.mytotalenergies" target="_blank">
            <img ngSrc="./assets/img/button-google-play.svg" class="h-16" alt="Google Play" height="40" width="135" />
          </a>
        </div>
      </div>
    </div>
  </div>
</ng-container>

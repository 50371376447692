<div class="w-full px-8 xl:max-w-screen-xl xl:w-full xl:m-auto md:mt-16">
  <div class="flex flex-wrap items-center">
    <div class="md:flex-1 order-2 my-16 ml-4 md:my-0 md:ml-0">
      <div class="text-center md:max-w-2xl md:mx-auto">
        <h3 class="text-center mb-4 font-medium font-sans">{{ 'components.pageNotFound.title' | translate }}</h3>
        <p class="font-sans mb-8" [innerHTML]="'components.pageNotFound.subtitle' | translate"></p>
        <a routerLink="/" href="/" class="btn btn-secondary">{{ 'components.pageNotFound.button' | translate }}</a>
      </div>
    </div>
    <div class="flex-1 mr-4 md:mr-0">
      <img src="./assets/img/errors/404.svg" alt="TotalEnergies - Page not found" class="max-w-full" />
    </div>
  </div>
</div>

<div class="step-wrapper">
  <div class="step mb-16">
    <h2 class="mb-8 font-sans font-medium text-3xl te-text-gradient-home inline-block w-full">
      {{ 'pages.move.newMeters.secondTitle' | translate }}
    </h2>

    <div [formGroup]="form">
      <div [ngSwitch]="step">
        <section *ngSwitchCase="NewMetersPages.DRE">
          <h3 class="text-2xl font-medium text-grey-dark">
            {{ 'pages.move.dreDocument.subtitle.moveIn' | translate }}
          </h3>
          <app-dre-document
            formGroupName="dre"
            [uploadInProgress]="fileUploadInProgress"
            (fileEmitter)="onFileSelection($event)"
          >
          </app-dre-document>
        </section>

        <section *ngSwitchCase="NewMetersPages.ELECTRICITY">
          <div *ngIf="meters?.hasElectricity; else gas">
            <app-electricity-meter
              formGroupName="meters"
              [registration]="registration"
              [submittedForm]="submittedForm"
              [moveInDate]="serviceDateForMoveIn"
              (formValidityEmitter)="setFormValidity()"
            >
            </app-electricity-meter>
          </div>
        </section>

        <section *ngSwitchCase="NewMetersPages.GAS">
          <ng-template [ngTemplateOutlet]="gas"></ng-template>
        </section>
      </div>

      <ng-template #gas>
        <app-gas-meter formGroupName="meters" [submittedForm]="submittedForm" (formValidityEmitter)="setFormValidity()">
        </app-gas-meter>
      </ng-template>
    </div>
  </div>
</div>

<app-navigation [stepValidity]="true" (nextClick)="onNextClicked()" (previousClick)="onPreviousClicked()">
</app-navigation>

<div class="md:min-w-[65rem] lg:w-[72rem] mx-4 sm:mx-0">
  <div class="py-8 px-8 sm:px-16 -mx-12 sm:-mx-16 -mt-8 sm:-mt-12 leading-[2.6rem] text-orange bg-orange-lighter">
    {{ 'components.consumption.index.monthlyInstallment.' + messageType + '.intro' | translate }}
  </div>

  <div class="pt-8 md:pt-16 pb-4 font-bold text-sm">
    {{ 'components.consumption.index.monthlyInstallment.suggestionText' | translate }}
  </div>

  <div class="flex flex-wrap gap-x-16 gap-y-4">
    <div class="rounded-2xl border border-solid border-grey grow-[2]">
      <div class="text-grey-dark relative p-8">
        <app-tooltip
          class="absolute right-4 top-4"
          [tooltip]="'components.consumption.index.monthlyInstallment.tooltip.installmentCalculation'"
          [tooltipParams]="tooltipParams"
          [position]="'left top'"
          [contentClass]="'!w-[30rem] md:!w-[45rem]'"
        ></app-tooltip>

        <div class="flex gap-x-8 sm:gap-x-16">
          <div>
            <span class="text-grey-dark60 font-medium text-sm">
              {{ 'components.consumption.index.monthlyInstallment.currentInstallment' | translate }}
            </span>
            <br />
            <span class="text-2xl font-medium">
              {{ currentAmount | currency : 'EUR' : 'symbol' : '1.2-2' }}
            </span>
            <small class="hidden"> /&nbsp;</small>
            <small class="hidden first-letter:visible invisible sm:visible">
              {{ 'components.consumption.index.monthlyInstallment.monthly' | translate }}
            </small>
          </div>
          <img src="./assets/img/arrow-left-long.svg" />
          <div>
            <span class="text-grey-dark60 font-medium text-sm">
              {{ 'components.consumption.index.monthlyInstallment.suggestedInstallment' | translate }}
            </span>
            <br />
            <span class="text-2xl font-medium">
              {{ suggestedAmount | currency : 'EUR' : 'symbol' : '1.2-2' }}
            </span>
            <small class="hidden"> /&nbsp;</small>
            <small class="hidden first-letter:visible invisible sm:visible">
              {{ 'components.consumption.index.monthlyInstallment.monthly' | translate }}
            </small>
          </div>
        </div>
      </div>
    </div>

    <div
      class="rounded-2xl border border-solid border-grey relative p-8 grow"
      [ngClass]="invoiceAdjustment > 0 ? 'bg-primary/5' : 'bg-success-lighter/5'"
    >
      <app-tooltip
        class="absolute right-4 top-4"
        [tooltip]="'components.consumption.index.monthlyInstallment.tooltip.installmentInvoice'"
        [position]="'left top'"
      ></app-tooltip>

      <span class="text-grey-dark60 font-medium text-sm">
        {{ 'components.consumption.index.monthlyInstallment.settlementInvoice' | translate }}
      </span>
      <br />
      <span class="text-2xl font-medium" [ngClass]="invoiceAdjustment > 0 ? 'text-primary' : 'text-success-lighter'">
        {{ invoiceAdjustment | currency : 'EUR' : 'symbol' : '1.2-2' }}
      </span>
    </div>
  </div>

  <div class="mb-8 mt-8 md:mt-16">
    <fc-installment-slider
      [energy]="energy"
      [maxAmount]="maxAmount"
      [minAmount]="minAmount"
      [currentAmount]="currentAmount"
      [suggestedAmount]="suggestedAmount"
      [control]="control"
    >
    </fc-installment-slider>
  </div>

  <div class="flex mb-4 md:justify-start justify-between gap-x-8">
    <button
      type="button"
      (click)="submit()"
      class="btn btn-primary grow md:grow-0 font-bold"
      [disabled]="updateButtonDisabled"
    >
      {{ 'general.update' | translate }}
    </button>
    <button
      type="button"
      (click)="continue()"
      [analyticsEvent]="'depositModification:cta-click:submit-deposit-amount'"
      class="btn btn-secondary grow md:grow-0 font-bold"
    >
      {{ 'general.ignore' | translate }}
    </button>
  </div>
</div>

<div *ngIf="numberOfActions === 0; else hasActions">
  <ng-template [ngTemplateOutlet]="noPdf"></ng-template>
</div>

<ng-template #hasActions>
  <div *ngIf="numberOfActions === 1 && actions?.downloadInvoice; else showDropdown" class="px-2 text-right">
    <div
      class="cursor-pointer"
      (click)="downloadPDF(invoice?.invoiceNumber)"
      [analyticsEvent]="'invoices:download:payment-plan'"
    >
      <ng-template [ngTemplateOutlet]="downloadInvoicePicto"></ng-template>
    </div>
  </div>
  <ng-template #showDropdown>
    <ng-template
      *ngIf="numberOfActions === 1 && actions?.payOnline"
      [ngTemplateOutlet]="noPdf"
      [ngTemplateOutletContext]="{ class: 'hidden sm:inline-block' }"
    >
    </ng-template>
    <span
      [class.sm:hidden]="numberOfActions === 1 && actions?.payOnline"
      ngbDropdown
      class="ml-auto w-full"
      placement="bottom-right"
    >
      <span class="cursor-pointer inline-block px-2" id="dropdownBasic1" ngbDropdownToggle>
        <img
          ngSrc="./assets/img/icons/standalone/red/actions.svg"
          class="float-right mr-[0.8rem]"
          alt="Actions"
          height="16"
          width="4"
        />
      </span>
      <ul ngbDropdownMenu aria-labelledby="dropdownBasic1" class="basic-style">
        <li ngbDropdownItem *ngIf="actions?.payOnline">
          <ng-template [ngTemplateOutlet]="paymentPicto"></ng-template>
          <app-invoice-pay-online [billingDetails]="billingDetails" [invoice]="invoice" [linkStyle]="true">
          </app-invoice-pay-online>
        </li>
        <li ngbDropdownItem *ngIf="actions?.downloadRegule">
          <span
            class="text-primary"
            (click)="getInvoiceDetails(invoice?.invoiceNumber)"
            [analyticsEvent]="'invoices:download:invoiceDetails'"
          >
            <ng-template [ngTemplateOutlet]="downloadPicto"></ng-template>
            {{ 'components.invoices.detailedInvoice' | translate }}
          </span>
        </li>
        <li ngbDropdownItem *ngIf="actions?.downloadInvoice">
          <span
            class="text-primary"
            (click)="downloadPDF(invoice?.invoiceNumber)"
            [analyticsEvent]="'invoices:download:payment-plan'"
          >
            <ng-template [ngTemplateOutlet]="downloadPicto"></ng-template>
            {{ 'components.invoices.downloadInvoice' | translate }}
          </span>
        </li>
      </ul>
    </span>
  </ng-template>
</ng-template>

<ng-template #noPdf let-class="class">
  <app-tooltip
    class="{{ class }} mt-2 nopdf-tooltip inline-block fl mr-[0.8rem]"
    [tooltip]="'components.invoices.pdfNotAvailable' | translate"
  >
  </app-tooltip>
</ng-template>

<ng-template #downloadPicto>
  <img
    ngSrc="./assets/img/icons/standalone/red/download.svg"
    class="inline-block mr-2"
    alt="Download"
    height="20"
    width="20"
  />
</ng-template>

<ng-template #downloadInvoicePicto>
  <img
    ngSrc="./assets/img/icons/standalone/red/download_pdf.svg"
    class="inline-block"
    alt="Download"
    height="24"
    width="24"
  />
</ng-template>

<ng-template #paymentPicto>
  <img
    ngSrc="./assets/img/icons/standalone/red/euro.svg"
    class="inline-block mr-2 w-[2.1rem]"
    alt="Download"
    height="800"
    width="800"
  />
</ng-template>

import { Injectable } from '@angular/core';
import { InvoicingMethod, MoveDTO, MoveFormFrontend, MoveRegister } from '@app/core/state/move.state';
import {
  ReadInvoiceDataCuzoApi,
  ReadInvoiceDataPaymentMethodEnumCuzoApi,
  UploadDocumentParamsDreCompletionStatusEnumCuzoApi,
} from '@app/shared/models/cuzo-be-contract';
import {
  BillingInfo,
  BillingMethod,
  MoveInRegistration,
  SwitchType,
  PaymentMethod,
  DeliveryPoint,
} from '@app/modules/customer-zone/move/models/movein.interface';
import { EnergyType } from '@app/modules/customer-zone/consumption/models/consumption.interface';
import {
  ElectricityMeterFrontend,
  GasMeterFrontend,
  IndexesFrontend,
} from '../../components/move-form/steps/new-meters/new-meters.interface';
import { MeterType } from '@app/modules/customer-zone/consumption/models/deliveryPoint.interface';
import { SMART } from '../../components/move-form/steps/new-meters/electricity-meter/electricity-meter.component';
import { NewOccupantStep } from '../../components/move-form/steps/new-occupant/new-occupant.interface';
import { NewAddressStep } from '../../components/move-form/steps/new-address/new-address.interface';

@Injectable({
  providedIn: 'root',
})
export class BackendToFrontendMapperService {
  static map(
    moveDTO: MoveDTO,
    registration: MoveInRegistration = null,
    newOccupantRegistration: MoveInRegistration = null
  ): MoveFormFrontend {
    if (!moveDTO || !moveDTO?.id) {
      return { supplier: null, movingInfo: null, newAddress: null, newMeters: null, newOccupant: null };
    }
    const localForm = JSON.parse(localStorage.getItem(moveDTO?.id))?.form;
    return {
      supplier: this.mapSupplierStep(moveDTO, localForm),
      movingInfo: this.mapMovingInfoStep(moveDTO, localForm),
      newAddress: this.mapNewAddressStep(registration, moveDTO, localForm),
      newMeters: this.mapNewMetersStep(registration, localForm),
      newOccupant: this.mapNewOccupantStep(newOccupantRegistration, moveDTO, localForm),
      billingInfo: this.mapBillingInfoStep(moveDTO, localForm),
    };
  }

  private static mapSupplierStep(moveDTO: MoveDTO, localForm: MoveFormFrontend) {
    return {
      amendmentField: moveDTO?.amendmentField ?? localForm?.supplier?.amendmentField,
    };
  }

  private static mapMovingInfoStep(moveDTO: MoveDTO, localForm: MoveFormFrontend) {
    const filename: string = moveDTO?.documents?.[moveDTO?.documents.length - 1]?.name || null;
    const dreStatus: UploadDocumentParamsDreCompletionStatusEnumCuzoApi =
      moveDTO?.sites?.[0]?.deliveryPoints?.[0]?.dreDocumentStatus;
    return {
      fileName: filename ?? localForm?.movingInfo?.fileName,
      dreStatus: dreStatus ?? localForm?.movingInfo?.dreStatus,
      movingDate: moveDTO?.ilcDate ?? localForm?.movingInfo?.movingDate,
    };
  }

  private static mapNewOccupantStep(
    registration: MoveInRegistration,
    moveDTO: MoveDTO,
    localForm: MoveFormFrontend
  ): NewOccupantStep {
    const newOccupantLocalStorage = localForm?.newOccupant;

    return {
      firstName: registration?.contact?.firstName ?? newOccupantLocalStorage?.firstName,
      lastName: registration?.contact?.lastName ?? newOccupantLocalStorage?.lastName,
      mobilePhone: registration?.contact?.mobilePhone ?? newOccupantLocalStorage?.mobilePhone,
      hasNewOccupant: registration?.id && moveDTO.knowBuyer ? true : newOccupantLocalStorage?.hasNewOccupant,
    };
  }

  private static mapNewAddressStep(
    registration: MoveInRegistration,
    moveDTO: MoveDTO,
    localForm: MoveFormFrontend
  ): NewAddressStep {
    const registrationSite = registration?.sites?.[0];
    const checkIfDeliveryPointsHasEnergy = (deliveryPoints: DeliveryPoint[], energyType: EnergyType) => {
      if (!deliveryPoints || deliveryPoints?.length === 0) {
        return null;
      }
      return deliveryPoints?.some((dp) => dp.energyType === energyType);
    };
    // When registration is not created yet, so del. point doesn't exist there, we take data from local storage
    const gasEnergyWasSelected =
      checkIfDeliveryPointsHasEnergy(registrationSite?.deliveryPoints, EnergyType.GAS) ??
      localForm?.newAddress.energyType.gas;
    const elecEnergyWasSelected =
      checkIfDeliveryPointsHasEnergy(registrationSite?.deliveryPoints, EnergyType.ELECTRICITY) ??
      localForm?.newAddress.energyType.electricity;

    const requestedServiceDate =
      registrationSite?.deliveryPoints[0].requestedServiceDate ?? localForm?.newAddress?.requestedServiceDate;

    return {
      newAddress: {
        address: registrationSite?.address?.street ?? localForm?.newAddress?.newAddress?.address,
        number: registrationSite?.address?.streetNumber ?? localForm?.newAddress?.newAddress?.number,
        box: registrationSite?.address?.box ?? localForm?.newAddress?.newAddress?.box,
        zipCode: registrationSite?.address?.zip ?? localForm?.newAddress?.newAddress?.zipCode,
        locality: registrationSite?.address?.city ?? localForm?.newAddress?.newAddress?.locality,
        country: registrationSite?.address?.country ?? localForm?.newAddress?.newAddress?.country,
      },
      invoicingData: {
        invoicingMethod:
          registration?.billingInfo?.invoiceSendingType ?? localForm?.newAddress?.invoicingData?.invoicingMethod,
      },
      registrationWasCreated: !!registration,
      // We will check service date first in move in registration, then local storage (var requestedServiceDate) and then move out (desired move out date - ilcDate)
      requestedServiceDate: requestedServiceDate ?? moveDTO.ilcDate,
      // When data for gas/elec selection was not saved in registration or local storage we will take that from move out
      energyType: {
        gas:
          gasEnergyWasSelected ?? checkIfDeliveryPointsHasEnergy(moveDTO?.sites?.[0]?.deliveryPoints, EnergyType.GAS),
        electricity:
          elecEnergyWasSelected ??
          checkIfDeliveryPointsHasEnergy(moveDTO?.sites?.[0]?.deliveryPoints, EnergyType.ELECTRICITY),
      },
    };
  }

  private static mapBillingInfoStep(moveDTO: MoveDTO, localForm: MoveFormFrontend): MoveFormFrontend['billingInfo'] {
    const billingInfo = moveDTO?.billingInfo;
    // Address object from backend
    const address = billingInfo?.address;
    // Address object from local storage
    const localAddress = localForm?.billingInfo?.billingAddress;
    return {
      billingAddress: {
        address: address?.street ?? localAddress?.address,
        number: address?.streetNumber ?? localAddress?.number,
        box: address?.box ?? localAddress?.box,
        zipCode: address?.zip ?? localAddress?.zipCode,
        locality: address?.city ?? localAddress?.locality,
        country: address?.country ?? localAddress?.country,
      },
      invoicingMethod: billingInfo?.invoiceSendingType ?? localForm?.billingInfo?.invoicingMethod,
    };
  }

  private static mapNewMetersStep(
    registration: MoveInRegistration,
    localForm: MoveFormFrontend
  ): MoveFormFrontend['newMeters'] {
    const [site] = registration?.sites || [];
    const filename: string = registration?.documents?.[registration?.documents.length - 1]?.name || null;
    const newMetersLocalStorage = localForm?.newMeters;

    const dre = newMetersLocalStorage?.dre.modifiedWithoutSave
      ? newMetersLocalStorage.dre
      : {
          dreStatus: site?.deliveryPoints?.[0]?.dreDocumentStatus,
          fileName: filename,
        };

    // When modifiedWithoutSave is:
    // - true we load data from local storage - data weren not saved to API
    // - false we load data from registration
    // - it's not created yet load data from registration/api. For example after open already created emove in new browser
    const elecMeters = newMetersLocalStorage?.meters?.electricity?.modifiedWithoutSave
      ? newMetersLocalStorage?.meters?.electricity
      : this.getElectricityDataFromRegistration(registration);
    const gasMeters = newMetersLocalStorage?.meters?.gas?.modifiedWithoutSave
      ? newMetersLocalStorage?.meters?.gas
      : this.getGasDataFromRegistration(registration);

    return {
      dre: {
        ...dre,
        modifiedWithoutSave: newMetersLocalStorage?.dre?.modifiedWithoutSave ?? null,
      },
      meters: {
        electricity: {
          ...elecMeters,
          modifiedWithoutSave: newMetersLocalStorage?.meters?.electricity?.modifiedWithoutSave ?? null,
        },
        gas: {
          ...gasMeters,
          modifiedWithoutSave: newMetersLocalStorage?.meters?.gas?.modifiedWithoutSave ?? null,
        },
      },
      step: newMetersLocalStorage?.step,
    };
  }

  private static getElectricityDataFromRegistration(registration: MoveInRegistration): ElectricityMeterFrontend {
    const [site] = registration?.sites || [];

    const elecDeliveryPoint = site?.deliveryPoints?.find((dp) => dp.energyType === EnergyType.ELECTRICITY);
    const [elecMeter] = elecDeliveryPoint?.meters || [];
    const elecIndexes = elecMeter?.registers;
    const elecNoInstalledMeter = elecDeliveryPoint?.switchType === SwitchType.MOVINGANDNEWBINDING;
    const { meterType, isExclusiveNight } = this.mapMeterType(elecMeter?.type);
    const smartMeterAndMeterType = elecMeter?.smartMeter ? SMART + meterType : meterType ?? null;

    return {
      noMeter: elecNoInstalledMeter,
      ean: elecDeliveryPoint?.code,
      number: elecMeter?.number,
      meterType: meterType,
      smartMeter: elecMeter?.smartMeter,
      smartMeterAndMeterType: smartMeterAndMeterType,
      exclusiveNight: isExclusiveNight,
      peakPower: site?.peakSolarPower,
      hasSolarPanels: site?.hasSolarPanels,
      solarPanelsKva: site?.solarPanelKVA,
      solarPanelsInstallationDateStarting2024: site?.solarPanelsInstallationDateStarting2024,
      injectionTariff: site?.hasInjectionTariff,
      indexes: elecIndexes && this.mapIndexes(elecIndexes),
    };
  }

  private static getGasDataFromRegistration(registration: MoveInRegistration): GasMeterFrontend {
    const [site] = registration?.sites || [];
    const gasDeliveryPoint = site?.deliveryPoints?.find((dp) => dp.energyType === EnergyType.GAS);
    const [gasMeter] = gasDeliveryPoint?.meters || [];
    const gasIndexes = gasMeter?.registers;
    const gasNoInstalledMeter = gasDeliveryPoint?.switchType === SwitchType.MOVINGANDNEWBINDING;

    return {
      noMeter: gasNoInstalledMeter,
      meterType: gasMeter?.type as MeterType,
      ean: gasDeliveryPoint?.code,
      number: gasMeter?.number,
      indexes: gasIndexes && this.mapIndexes(gasIndexes),
    };
  }

  private static mapMeterType(meterType: MeterType): { meterType: MeterType; isExclusiveNight: boolean } {
    let meterTypeWithoutExcl: MeterType;
    let isExclusiveNight: boolean;
    switch (meterType) {
      case MeterType.MONO_EXC:
        meterTypeWithoutExcl = MeterType.MONO;
        isExclusiveNight = true;
        break;
      case MeterType.BI_EXC:
        meterTypeWithoutExcl = MeterType.BI;
        isExclusiveNight = true;
        break;
      default:
        meterTypeWithoutExcl = meterType;
        isExclusiveNight = false;
    }
    return { meterType: meterTypeWithoutExcl, isExclusiveNight };
  }

  private static mapIndexes(indexes: MoveRegister[]): IndexesFrontend[] {
    return indexes.map((index) => ({
      timeFrame: index.timeFrame,
      type: index.type,
      value: index.value,
    }));
  }

  // We map object from cuzo backend to send it back to cuzo backend for regstar in another format via the front.
  // This is nonsense
  static mapReadInvoiceDataToBillingInfo(readInvoiceData: ReadInvoiceDataCuzoApi): BillingInfo {
    // Map payment method
    const paymentMethodMap: Record<ReadInvoiceDataPaymentMethodEnumCuzoApi, PaymentMethod> = {
      [ReadInvoiceDataPaymentMethodEnumCuzoApi.BANK_DOMICILIATION]: PaymentMethod.BANK_DOMICILIATION,
      [ReadInvoiceDataPaymentMethodEnumCuzoApi.DIRECT_DEBIT]: PaymentMethod.BANK_DOMICILIATION,
      [ReadInvoiceDataPaymentMethodEnumCuzoApi.BANK_TRANSFER]: PaymentMethod.BANK_TRANSFER,
      [ReadInvoiceDataPaymentMethodEnumCuzoApi.DIRECT_DEBIT_AND_BANK_TRANSFER]:
        PaymentMethod.BANK_DOMICILIATION_AND_BANK_TRANSFER,
    };

    // Prepare billing info
    const billingInfo: BillingInfo = {
      // Directly mapped fields
      iban: readInvoiceData.bankAccount,
      bic: readInvoiceData.bankAccountBIC,

      // Map payment method
      paymentMethod: readInvoiceData.paymentMethod ? paymentMethodMap[readInvoiceData.paymentMethod] : undefined,

      // Derive electronic invoicing and invoice sending type
      electronicInvoicing:
        readInvoiceData.invoiceDeliveryChannel === InvoicingMethod.EMAIL ||
        readInvoiceData.invoiceDeliveryChannel === InvoicingMethod.ELECTRONIC,

      invoiceSendingType: readInvoiceData.invoiceDeliveryChannel,

      // Refund account details
      refundIban: readInvoiceData.refundAccount,
      refundBic: readInvoiceData.refundAccountBIC,
    };

    // Map billing method (payment frequency)
    const billingMethodMap: Record<string, BillingMethod> = {
      MONTHLY: BillingMethod.MONTHLY,
      BIMONTHLY: BillingMethod.BIMONTHLY,
      // Add more mappings if needed
    };

    if (readInvoiceData.paymentFrequency) {
      billingInfo.billingMethodType =
        billingMethodMap[readInvoiceData.paymentFrequency.toUpperCase()] || BillingMethod.MONTHLY; // default to monthly if not matched
    }

    // Additional fields with somewhat complex mapping
    billingInfo.bankName = undefined; // No direct mapping available
    billingInfo.refundBankName = undefined; // No direct mapping available

    // Flag indicating if invoice sending method is locked
    billingInfo.useDeliveryAddress = readInvoiceData.invoiceSendingMethodLocked ?? false;

    return billingInfo;
  }
}

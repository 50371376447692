<div class="relative h-full" *ngIf="volumes$ | observableLoadingError | async as volumes">
  <ng-container *ngIf="!volumes?.loading && !volumes?.error">
    <ng-container *ngIf="volumes?.value && hasValues(volumes?.value); else dataError">
      <div class="card-primary-small relative">
        <app-tooltip
          *ngIf="meterConfig !== MeterConfig.basic"
          [tooltip]="
            'components.widgets.monthlyConsumption.tooltip.' +
            (activeEnergyType === EnergyType.ELECTRICITY ? meterConfig : 'gas')
          "
          class="hidden md:block absolute right-4 top-4"
        ></app-tooltip>
        <app-warning-tooltip
          *ngIf="meterConfig === MeterConfig.basic"
          [tooltip]="
            'components.widgets.monthlyConsumption.tooltip.' +
            (activeEnergyType === EnergyType.ELECTRICITY ? meterConfig : 'gas')
          "
          [warning]="'components.widgets.monthlyConsumption.tooltipWarning'"
          class="hidden md:block absolute right-4 top-4"
        ></app-warning-tooltip>
        <div class="flex items-center md:border-b border-grey md:mt-4 mb-8 md:pb-8 md:mx-8">
          <div class="flex justify-start min-w-[18rem]">
            <te-switcher
              class="inline-block w-auto md:mt-0"
              [options]="energyOptions"
              [active]="activeEnergyType"
              [translationPrefix]="'general.product.'"
              (switchAction)="switchEnergy($event)"
            >
            </te-switcher>
          </div>
          <div class="hidden md:flex md:justify-center flex-1 font-medium text-lg text-grey text-center">
            <ng-template [ngTemplateOutlet]="title"></ng-template>
          </div>
          <div class="hidden md:flex justify-end items-center">
            <ng-container
              *ngIf="
                activeEnergyType === EnergyType.ELECTRICITY && meterConfig === MeterConfig.smartAndSolar;
                else empty
              "
            >
              <span class="font-bold text-sm pr-4">
                {{ 'components.widgets.monthlyConsumption.injectionToggle' | translate }}
              </span>
              <app-toggle-switch
                class="z-0"
                [options]="{ id: 'consumption-energy-switch' }"
                [checked]="displayInjection"
                (toggleSwitched)="toggleInjection($event)"
              ></app-toggle-switch>
            </ng-container>
            <ng-template #empty>
              <div class="w-60"></div>
            </ng-template>
          </div>
        </div>
        <div class="font-bold text-xs mb-4 -mt-2 ml-4">
          {{ 'general.units.kwh' | translate }}
          <span class="inline-block md:hidden font-medium text-grey pl-2">
            <ng-template [ngTemplateOutlet]="title"></ng-template>
          </span>
        </div>
        <app-consumption-bar-chart
          [barCharDatasets]="consumptionBarCharDatasets"
          [tooltipOptions]="tooltipOptions"
          [legendAlign]="'start'"
        ></app-consumption-bar-chart>
        <div class="xl:absolute xl:bottom-8 xl:right-12 pb-2 text-right hidden">
          <a [href]="" target="_blank" class="btn-text inline-block text-primary font-bold">
            {{ 'components.widgets.monthlyConsumption.consumptionDetail' | translate }}
          </a>
        </div>
      </div>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="volumes.loading">
    <app-loader-widget></app-loader-widget>
  </ng-container>

  <ng-container *ngIf="volumes.error">
    <ng-container *ngIf="volumes?.error?.status === 428; else dataError">
      <app-no-data-widget>
        <span class="inline-block w-full">
          {{ 'components.widgets.noData.label' | translate }}
        </span>
        <a class="text-primary" routerLink="/consumptions/home-profile">
          {{ 'components.widgets.noData.updateProfile' | translate }}
        </a>
      </app-no-data-widget>
    </ng-container>
  </ng-container>
  <ng-template #dataError>
    <app-error-widget></app-error-widget>
  </ng-template>
</div>

<ng-template #title>
  <ng-container *ngIf="profile; else year">
    <ng-container *ngIf="profile?.smart === false && profile?.injection === 0">
      {{ 'components.widgets.monthlyConsumption.title.basic' | translate : { currentYear } }}
    </ng-container>
    <ng-container *ngIf="profile?.smart">
      {{ 'components.widgets.monthlyConsumption.title.smart' | translate : { currentYear } }}
    </ng-container>
    <ng-container *ngIf="profile?.smart === false && profile?.injection > 0">
      {{ currentYear }}
    </ng-container>
  </ng-container>
  <ng-template #year>
    {{ currentYear }}
  </ng-template>
</ng-template>

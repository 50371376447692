<div class="register px-8 xl:max-w-screen-xl xl:w-full xl:m-auto flex flex-1 flex-col">
  <div class="flex-1 pt-16 pb-8 md:mt-8" [ngClass]="{ confirmation__body: !showForm }">
    <!-- form part -->
    <ng-container *ngIf="showForm">
      <div class="inline-block w-full text-center mx-auto">
        <h2
          class="font-display font-normal text-5xl te-text-gradient-home inline-block leading-snug w-full sm:max-w-4xl"
          [innerHTML]="'pages.onboarding.createAccount.title' | translate"
        ></h2>
      </div>
      <div class="w-full sm:max-w-2xl mx-auto py-10">
        <form [formGroup]="emailForm" novalidate class="form" (ngSubmit)="handleSubmit()">
          <div class="form-element input-text" [class.errors]="error">
            <label for="input-email">
              {{ 'pages.onboarding.createAccount.email' | translate }}
            </label>
            <input
              (blur)="checkEmail()"
              [formControl]="emailForm.controls.email"
              id="input-email"
              name="email"
              value=""
              type="email"
              autocomplete="off"
            />
            <div class="message">
              <div *ngIf="error">{{ 'errorMessages.invalidEmail' | translate }}</div>
            </div>
          </div>
          <div class="mt-8 text-center inline-block w-full md:flex md:text-left">
            <button
              [attr.disabled]="error || !emailForm.valid || emailForm.pristine ? '' : null"
              [class.not-submittable]="error || !emailForm.valid || emailForm.pristine"
              class="btn btn-primary inline-block w-full font-bold mb-4 md:mb-0 md:w-auto md:order-last"
              type="submit"
              [analyticsEvent]="'registration:click:send-email'"
            >
              {{ 'pages.onboarding.createAccount.nextStep' | translate }}
            </button>
            <span class="navigate-back text-sm text-primary cursor-pointer font-bold md:flex-1">
              <a (click)="goBack()" [analyticsEvent]="'registration:click:back'">
                {{ 'pages.emailSent.return' | translate }}
              </a>
            </span>
          </div>
        </form>
      </div>
    </ng-container>
    <!-- confirmation part -->
    <ng-container *ngIf="!showForm">
      <div class="inline-block w-full text-center mx-auto">
        <h2
          class="font-display font-normal text-5xl te-text-gradient-home inline-block leading-snug w-full sm:max-w-4xl"
          [innerHTML]="'pages.emailSent.emailSentMessage' | translate"
        ></h2>
        <span class="font-medium text-2xl inline-block w-full mt-12">
          {{ 'pages.emailSent.clickLinkMessage' | translate }}
        </span>
      </div>
      <div class="text-center mt-16">
        <span class="font-bold"> {{ 'pages.emailSent.sentToAddress' | translate }} </span>
        {{ emailForm.controls.email.value }}
      </div>
      <div class="text-center my-16">
        <span
          (click)="submitEmailAddress()"
          class="btn-text text-primary font-bold cursor-pointer"
          [analyticsEvent]="'registration:click:resend-email'"
        >
          {{ 'pages.emailSent.sendAgain' | translate }}
        </span>
      </div>
      <div class="text-center mt-8">
        <span class="navigate-back text-sm text-primary cursor-pointer font-bold">
          <a (click)="goBack()" [analyticsEvent]="'registration:click:back'">
            {{ 'pages.emailSent.return' | translate }}
          </a>
        </span>
      </div>
    </ng-container>
  </div>

  <div class="my-8"></div>
</div>

<div class="{{ class }} {{ style | lowercase }}-style">
  <div [class.md:flex]="style === InputMeterStyle.COLUMN">
    <div class="flex-1 lg:flex-auto relative md:mt-4">
      <div class="inline-block">
        <label
          class="form__label form__label--inline {{
            newType !== 'none' || type !== 'none'
              ? 'meter-label meter-label--' + (newType !== 'none' ? (newType | lowercase) : type)
              : ''
          }}"
        >
          {{ label }} ({{ unit }})
          <app-tooltip *ngIf="tooltip && tooltip.trim()" class="inline-block align-top pl-2" [tooltip]="tooltip">
          </app-tooltip>
        </label>
        <small *ngIf="value && date" class="last-index">
          {{
            'components.estimatedIndexDetail.lastIndex'
              | translate : { date: date | date : 'dd/MM/yyyy', value: value | number : '1.2-2', unit: unit }
          }}
          <ng-container *ngIf="(type | uppercase) === registerTypeExtra.NOT_USED">
            <br />
            {{ 'components.estimatedIndexDetail.not_used' | translate }}
          </ng-container>
        </small>
      </div>
    </div>
    <div class="index-column md:ml-auto pl-12 md:pl-0">
      <div
        class="form-element input-text meter-index mt-4"
        [class.error]="
          errors?.required ||
          errors?.minimumNotReached ||
          (control.get('unit').touched && control.get('unit').errors) ||
          (control.get('decimal').touched && control.get('decimal').errors)
        "
      >
        <input
          type="tel"
          class="form__input meter-index__unit meter-index__error"
          id="indexDayUnit"
          maxlength="6"
          pattern="\d*"
          placeholder="000000"
          (keydown)="numericOnly($event)"
          (paste)="handlePastedContent($event, control.get('unit'))"
          [formControl]="control.get('unit')"
        />
        <span class="meter-index__comma">,</span>
        <input
          type="tel"
          class="form__input meter-index__decimal"
          id="indexDayDecimal"
          maxlength="1"
          placeholder="0"
          (keypress)="numericOnly($event)"
          (paste)="handlePastedContent($event, control.get('decimal'))"
          [formControl]="control.get('decimal')"
        />
      </div>
      <div
        class="error-block inline-block w-full mb-6 text-sm leading-8 {{
          errors?.higherThanAverage ? 'text-warning' : 'text-error'
        }}"
      >
        <div class="inline-block w-full mt-2">
          <p *ngIf="control.get('unit').touched && control.get('unit').hasError('required')">
            {{ 'La consommation est requise' }}
          </p>
          <p *ngIf="control.get('unit').touched && control.get('unit').hasError('minlength')">
            {{ 'La consommation nécéssite un nombre à 6 chiffres et une décimale' }}
          </p>
          <p *ngIf="control.get('decimal').touched && control.get('decimal').hasError('required')">
            {{ 'La décimal est requise' }}
          </p>
          <p *ngIf="control.get('decimal').touched && control.get('decimal').hasError('minlength')">
            {{ 'La décimal est un chiffre de 0 à 9' }}
          </p>
          <ng-container *ngIf="errors?.higherThanAverage"> {{ errors?.higherThanAverage | translate }}</ng-container>
          &nbsp;
        </div>
      </div>
    </div>
  </div>
</div>

<div class="{{ icon ? 'icon' : 'no-icon' }}" [ngSwitch]="invoice?.status">
  <div *ngSwitchCase="Status.DUE">
    <ng-template [ngTemplateOutlet]="info"></ng-template>
    {{
      'general.invoice.status.due.description'
        | translate
          : {
              dueDate: invoice.dueDate | date : 'dd.MM.yy' : undefined : facade.translate.currentLang + '-BE'
            }
    }}
  </div>

  <div *ngSwitchCase="Status.PART_STILL_DUE">
    <ng-template [ngTemplateOutlet]="info"></ng-template>
    {{
      'general.invoice.status.part_still_due.description'
        | translate
          : {
              balance: invoice.balance | currency : 'EUR' : 'symbol' : '1.2-2',
              dueDate: invoice.dueDate | date : 'dd.MM.yy' : undefined : facade.translate.currentLang + '-BE'
            }
    }}
  </div>

  <div *ngSwitchCase="Status.OVERDUE">
    <ng-template [ngTemplateOutlet]="error"></ng-template>
    <span
      [innerHTML]="
        'general.invoice.status.overdue.description'
          | translate
            : {
                dueDate: invoice.dueDate | date : 'dd.MM.yy' : undefined : facade.translate.currentLang + '-BE'
              }
      "
    >
    </span>
  </div>

  <div *ngSwitchCase="Status.PART_OVERDUE">
    <ng-template [ngTemplateOutlet]="error"></ng-template>
    <span
      [innerHTML]="
        'general.invoice.status.part_overdue.description'
          | translate
            : {
                balance: invoice.balance | currency : 'EUR' : 'symbol' : '1.2-2',
                dueDate: invoice.dueDate | date : 'dd.MM.yy' : undefined : facade.translate.currentLang + '-BE'
              }
      "
    >
    </span>
  </div>

  <div *ngSwitchCase="Status.PAID">
    <ng-template [ngTemplateOutlet]="success"></ng-template>
    <ng-container *ngIf="invoice.paymentDate; else paidMessage">
      {{
        'general.invoice.status.paidOn.description'
          | translate
            : {
                paymentDate: invoice.paymentDate | date : 'dd.MM.yy' : undefined : facade.translate.currentLang + '-BE'
              }
      }}
    </ng-container>
    <ng-template #paidMessage>
      {{ 'general.invoice.status.paid.description' | translate }}
    </ng-template>
  </div>

  <div *ngSwitchCase="Status.PAID_THROUGH_PAYMENT_PLAN">
    <ng-template [ngTemplateOutlet]="success"></ng-template>
    {{ 'general.invoice.status.paid_through_payment_plan.description' | translate }}
  </div>

  <div *ngSwitchCase="Status.CONVERTED_IN_PAYMENT_PLAN">
    <ng-template [ngTemplateOutlet]="stopwatch"></ng-template>
    {{ 'general.invoice.status.converted_in_payment_plan.description' | translate }}
  </div>

  <div *ngSwitchCase="Status.DIRECT_DEBIT_PLANNED">
    <ng-template [ngTemplateOutlet]="success"></ng-template>
    {{
      'general.invoice.status.direct_debit_planned.description'
        | translate
          : {
              dueDate: invoice.dueDate | date : 'dd.MM.yy' : undefined : facade.translate.currentLang + '-BE'
            }
    }}
  </div>

  <div *ngSwitchCase="Status.DIRECT_DEBIT_REJECTED">
    <ng-template [ngTemplateOutlet]="info"></ng-template>
    {{ 'general.invoice.status.direct_debit_rejected.description' | translate }}
  </div>

  <div *ngSwitchCase="Status.REFUND_PLANNED">
    <ng-template [ngTemplateOutlet]="success"></ng-template>
    {{
      'general.invoice.status.refund_planned.description'
        | translate
          : {
              plannedDate:
                invoice?.invoiceDate
                | addDaysToDate : 18
                | date : 'dd.MM.yy' : undefined : facade.translate.currentLang + '-BE'
            }
    }}
  </div>

  <div *ngSwitchCase="Status.PART_REFUND">
    <ng-template [ngTemplateOutlet]="info"></ng-template>
    {{ 'general.invoice.status.part_refund.description' | translate }}
  </div>

  <div *ngSwitchCase="Status.REFUND">
    <ng-template [ngTemplateOutlet]="success"></ng-template>
    {{ 'general.invoice.status.refund.description' | translate }}
  </div>

  <div *ngSwitchCase="Status.NO_REFUND_ACCOUNT">
    <ng-template [ngTemplateOutlet]="info"></ng-template>
    {{ 'general.invoice.status.no_refund_account.description' | translate }}
  </div>

  <div *ngSwitchCase="Status.REFUND_ON_HOLD">
    <ng-template [ngTemplateOutlet]="error"></ng-template>
    {{ 'general.invoice.status.refund_on_hold.description' | translate }}
  </div>

  <div *ngSwitchCase="Status.CANCELLED">
    <ng-template [ngTemplateOutlet]="error"></ng-template>
    {{ 'general.invoice.status.cancelled.description' | translate }}
  </div>

  <div *ngSwitchCase="Status.CANCELLATION">
    <ng-container *ngIf="invoice?.cancelledInvoiceNumbers?.length">
      <ng-template [ngTemplateOutlet]="info"></ng-template>
      <ng-container *ngIf="invoice?.cancelledInvoiceNumbers?.length > 1; else single">
        {{ 'general.invoice.status.cancellation.description.multi' | translate }}
        <span
          *ngFor="let invoiceNumber of invoice?.cancelledInvoiceNumbers; let i = index"
          (click)="downloadPDF(invoiceNumber)"
          class="text-primary cursor-pointer"
        >
          {{ invoiceNumber }}<span *ngIf="i < invoice?.cancelledInvoiceNumbers?.length - 1">, </span>
        </span>
      </ng-container>
      <ng-template #single>
        {{ 'general.invoice.status.cancellation.description.single' | translate }}
        <span (click)="downloadPDF(invoice?.cancelledInvoiceNumbers[0])" class="text-primary cursor-pointer">
          {{ invoice?.cancelledInvoiceNumbers[0] }}
        </span>
      </ng-template>
    </ng-container>
  </div>
</div>

<ng-template #success>
  <img *ngIf="icon" ngSrc="./assets/img/icon_valid.svg" class="inline-block md:mr-2" height="24" width="24" />
</ng-template>
<ng-template #info>
  <img *ngIf="icon" ngSrc="./assets/img/icon_info.svg" class="inline-block md:mr-2" height="24" width="24" />
</ng-template>
<ng-template #error>
  <img *ngIf="icon" ngSrc="./assets/img/icon_error.svg" class="inline-block md:mr-2" height="24" width="24" />
</ng-template>

<ng-template #stopwatch>
  <img *ngIf="icon" ngSrc="./assets/img/stopwatch.svg" class="inline-block md:mr-2" height="24" width="24" />
</ng-template>

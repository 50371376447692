<div class="mb-8 sm:mb-0 relative h-full">
  <div class="card-primary-small relative">
    <h3 class="text-2xl font-medium mb-4">{{ 'components.widgets.encodeIndexes.title' | translate }}</h3>
    <ng-container *ngIf="metersReading$ | async as metersReading; else loading">
      <div *ngFor="let meterReading of metersReading">
        <div *ngIf="meterReading.reading.length > 0; else noData">
          <ng-container *ngFor="let singleReading of meterReading?.reading | slice : 0 : 1">
            <div class="flex mt-4">
              <span class="text-sm font-medium text-grey-dark">
                <img
                  ngSrc="./assets/img/toggle/{{ meterReading.energy | lowercase }}.svg"
                  height="17"
                  width="17"
                  class="inline-block align-middle"
                />
                {{ 'general.product.' + meterReading.energy | lowercase | translate }}
              </span>
              <span class="font-bold text-grey-dark60 text-xs ml-auto">
                {{
                  singleReading.indexDate
                    | date : 'd MMMM yyyy' : undefined : facade.translate.currentLang + '-BE'
                    | titlecase
                    | lowercase
                }}
              </span>
            </div>
            <ul class="bg-grey-light rounded-2xl p-6 mt-4">
              <li
                *ngFor="let index of singleReading?.indexes"
                class="flex border-b border-white pb-2 mb-2 last:border-none last:pb-0 last:mb-0"
              >
                <span class="relative text-sm font-medium text-grey-dark pl-8">
                  <img
                    ngSrc="./assets/img/toggle/{{ index.register | lowercase }}.svg"
                    height="17"
                    width="17"
                    class="register-icon {{ index.register | lowercase }} absolute left-0 inline-block align-middle"
                  />
                  {{
                    'general.meters.' +
                      ([direction.production, direction.injection].includes(index.direction)
                        ? direction.production + '_'
                        : '') +
                      index.register +
                      '.title' | translate
                  }}
                </span>
                <span class="text-sm font-medium text-grey-dark ml-auto">
                  {{ index.value | number : '1.0-1' : facade.translate.currentLang + '-BE' }}
                  {{
                    'general.units.' +
                      (meterReading.energy === energyType.ELECTRICITY ? energyUnits.kwh : energyUnits.m3) | translate
                  }}
                </span>
              </li>
            </ul>
          </ng-container>
        </div>
      </div>
      <div class="w-full md:w-auto md:self-center mt-8">
        <button
          (click)="openPopup()"
          [analyticsEvent]="'orangeBlock:cta-click:meter-reading'"
          class="btn btn-primary font-bold inline-block w-full"
        >
          {{ 'components.widgets.encodeIndexes.button' | translate }}
        </button>
      </div>
    </ng-container>
  </div>
</div>

<ng-template #loading>
  <app-loader-widget></app-loader-widget>
</ng-template>

<ng-template #noData> Aucun index encodé </ng-template>

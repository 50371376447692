import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavigationComponent } from '@app/modules/customer-zone/move/components/move-form/navigation/navigation.component';
import { LoaderStatus } from '@app/modules/customer-zone/move/models/status.interface';
import { catchError, filter, Observable, take, timer } from 'rxjs';
import { MoveFormFacade } from '@app/core/facade/move-form.facade';
import { INITIAL_MOVE_STATE, MoveFormFrontend, MoveState } from '@app/core/state/move.state';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { EnergyType } from '@app/modules/customer-zone/consumption/models/consumption.interface';
import { DatePickerFieldModule } from '@app/shared/date-picker-field/date-picker-field.module';
import { ElectricityMeterComponent } from '@app/modules/customer-zone/move/components/move-form/steps/new-meters/electricity-meter/electricity-meter.component';
import { DeliveryPoint, MoveInRegistration, SwitchType } from '@app/modules/customer-zone/move/models/movein.interface';
import { MoveFormStep } from '@app/modules/customer-zone/move/components/move-form/steps/MoveFormStep';
import { GasMeterComponent } from '@app/modules/customer-zone/move/components/move-form/steps/new-meters/gas-meter/gas-meter.component';
import { markFormGroupTouched } from '@app/shared/utils/utils.validators';
import { AlertType } from '@app/shared/components/alert/alert.interface';
import {
  ElectricityMeterFrontend,
  GasMeterFrontend,
  NewMetersFormFormGroup,
  NewMetersPages,
  NewMetersPagesType,
  NewMetersStepDre,
  NewMetersStepFormDre,
} from './new-meters.interface';
import { TranslateModule } from '@ngx-translate/core';
import { MeterType } from '@app/modules/customer-zone/consumption/models/deliveryPoint.interface';
import { DreDocumentComponent } from '@app/modules/customer-zone/move/components/move-form/parts/dre-document/dre-document.component';
import { UploadDocumentParamsDreCompletionStatusEnumCuzoApi } from '@app/shared/models/cuzo-be-contract';
import { MainFacade } from '@app/core/facade/main.facade';

@Component({
  selector: 'app-new-meters',
  standalone: true,
  imports: [
    CommonModule,
    NavigationComponent,
    DatePickerFieldModule,
    FormsModule,
    ReactiveFormsModule,
    DreDocumentComponent,
    ElectricityMeterComponent,
    GasMeterComponent,
    TranslateModule,
  ],
  templateUrl: './new-meters.component.html',
  styleUrls: ['./new-meters.component.scss'],
})
export class NewMetersComponent extends MoveFormStep<MoveInRegistration> implements OnInit {
  form: FormGroup<NewMetersFormFormGroup>;
  NewMetersPages = NewMetersPages;
  step: NewMetersPagesType = NewMetersPages.DRE;
  meters: { hasElectricity: boolean; hasGas: boolean };
  registration: MoveInRegistration;
  submittedForm: boolean = false;
  readonly AlertType = AlertType;
  fileUploadInProgress: boolean = false;
  private allSteps = [NewMetersPages.DRE];

  constructor(
    protected readonly moveFormFacade: MoveFormFacade,
    private readonly facade: MainFacade,
    private readonly changeDetectorRef: ChangeDetectorRef
  ) {
    super(moveFormFacade);

    this.form = new FormGroup<NewMetersFormFormGroup>({
      meters: new FormGroup({}),
      dre: new FormGroup({
        dreStatus: new FormControl(null, [Validators.required]),
        fileForPartialStatus: new FormControl(null, [Validators.required]),
        fileForFullStatus: new FormControl(null, [Validators.required]),
      }),
    });
  }

  ngOnInit(): void {
    this.moveFormFacade.state$
      .pipe(
        filter((): boolean => this.moveFormFacade.state$.value !== INITIAL_MOVE_STATE),
        take(1)
      )
      .subscribe((state: MoveState): void => {
        this.registration = state?.registration;
        this.meters = {
          hasElectricity: state.form.newAddress.energyType.electricity,
          hasGas: state.form.newAddress.energyType.gas,
        };
        this.setUpNumberOfNewMeterPages();
        this.step = this.moveFormFacade.state$?.value?.form?.newMeters?.step || NewMetersPages.DRE;
        this.registration = this.syncDeliveryPoints(this.registration);
        const savedData = state?.form?.newMeters;

        if (savedData?.dre) {
          const formValues = this.mapSavedDateToForm(savedData.dre);
          this.form.controls.dre.patchValue(formValues);
        }
      });
  }

  get serviceDateForMoveIn(): string {
    return this.registration.sites[0].deliveryPoints[0].requestedServiceDate;
  }

  private setUpNumberOfNewMeterPages(): void {
    const numberOfPagesForNewStep = {
      elec: [NewMetersPages.DRE, NewMetersPages.ELECTRICITY],
      gas: [NewMetersPages.DRE, NewMetersPages.GAS],
      elecAndGas: [NewMetersPages.DRE, NewMetersPages.ELECTRICITY, NewMetersPages.GAS],
    };

    const calculateNewMeterSteps = () => {
      if (this.meters?.hasElectricity && this.meters.hasGas) {
        return numberOfPagesForNewStep.elecAndGas;
      } else if (this.meters?.hasElectricity) {
        return numberOfPagesForNewStep.elec;
      } else {
        return numberOfPagesForNewStep.gas;
      }
    };

    this.allSteps = calculateNewMeterSteps();
  }

  private mapSavedDateToForm(dre: NewMetersStepDre): NewMetersStepFormDre {
    return {
      dreStatus: dre?.dreStatus,
      fileForPartialStatus:
        dre?.dreStatus === UploadDocumentParamsDreCompletionStatusEnumCuzoApi.PARTIAL && dre?.fileName
          ? this.moveFormFacade.createEmptyFile(dre?.fileName)
          : null,
      fileForFullStatus:
        dre?.dreStatus === UploadDocumentParamsDreCompletionStatusEnumCuzoApi.FULL && dre?.fileName
          ? this.moveFormFacade.createEmptyFile(dre?.fileName)
          : null,
    };
  }

  private checkStepValidity(): boolean {
    // In this step we have 3 "inner" steps, so we need to check which one is currently active
    let isCurrentStepValid = false;

    if (this.step === NewMetersPages.DRE) {
      isCurrentStepValid = this.form.controls.dre.valid;
    } else if (this.step === NewMetersPages.ELECTRICITY && this.meters.hasElectricity) {
      isCurrentStepValid = this.form.controls.meters.controls.electricity.valid;
    } else {
      isCurrentStepValid = this.form.controls.meters.controls.gas.valid;
    }

    return isCurrentStepValid;
  }

  onNextClickedDefault(): void {
    let isCurrentStepValid = this.checkStepValidity();

    if (isCurrentStepValid) {
      this.moveFormFacade.loader$.next(LoaderStatus.LOADING);
      const isLastStep = this.step === this.allSteps[this.allSteps.length - 1];
      this.saveModifiedStateToLocalStorage(this.moveFormFacade.state$.value.form, this.step, isLastStep, false);

      this.saveFormData().subscribe(() => {
        this.moveFormFacade.loader$.next(LoaderStatus.LOADED);

        if (isLastStep) {
          this.moveFormFacade.next();
        } else {
          this.step = this.getPreviousOrNextStep(true);
        }
      });
    } else {
      this.submittedForm = true;
      markFormGroupTouched(this.form);
    }
  }

  onPreviousClickedDefault(): void {
    this.moveFormFacade.loader$.next(LoaderStatus.LOADING);
    timer(500)
      .pipe(take(1))
      .subscribe((): void => {
        this.moveFormFacade.loader$.next(LoaderStatus.LOADED);
        if (this.step === NewMetersPages.DRE) {
          this.saveFormToLocalStorage(this.step, this.step);
          this.moveFormFacade.previous();
        } else {
          this.saveFormToLocalStorage(this.step, this.getPreviousOrNextStep(false));
          this.step = this.getPreviousOrNextStep(false);
        }
      });
  }

  getPreviousOrNextStep(nextStep: boolean): NewMetersPages {
    const currentStepIndex = this.allSteps.indexOf(this.step);
    return nextStep ? this.allSteps[currentStepIndex + 1] : this.allSteps[currentStepIndex - 1];
  }

  setFormValidity(): void {
    this.changeDetectorRef.detectChanges();
  }

  saveFormData(): Observable<MoveInRegistration> {
    const registration = this.getUpdatedRegistration();

    return this.moveFormFacade.updateRegistration(registration);
  }

  private syncDeliveryPoints(registration: MoveInRegistration): MoveInRegistration {
    const deliveryPoints = registration?.sites?.[0]?.deliveryPoints;
    if (!deliveryPoints || deliveryPoints.length === 0) {
      return registration;
    }

    const updateDeliveryPoints = (energyType: EnergyType, hasMeter: boolean) => {
      if (hasMeter) {
        if (!this.hasMeter(deliveryPoints, energyType)) {
          deliveryPoints.push({ energyType });
        }
      } else {
        registration.sites[0].deliveryPoints = deliveryPoints.filter((dp) => dp.energyType !== energyType);
      }
    };

    updateDeliveryPoints(EnergyType.ELECTRICITY, this.meters.hasElectricity);
    updateDeliveryPoints(EnergyType.GAS, this.meters.hasGas);

    return registration;
  }

  private saveFormToLocalStorage(currentStep: NewMetersPages, nextStep: NewMetersPages, navigateBack = true): void {
    this.moveFormFacade.updateData({ form: this.getForm(currentStep, nextStep, navigateBack) });
    this.moveFormFacade.saveStateToLocalStorage();
  }

  private saveModifiedStateToLocalStorage(
    currentForm: MoveFormFrontend,
    currentStep: NewMetersPages,
    isLastStep: boolean,
    navigateBack = true
  ): void {
    const form = this.setModifiedState(currentForm, currentStep, navigateBack);
    form.newMeters.step = isLastStep ? this.step : this.getPreviousOrNextStep(true);
    this.moveFormFacade.updateData({ form });
    this.moveFormFacade.saveStateToLocalStorage();
  }

  onFileSelection(file: File): void {
    if (
      this.form.controls.dre.controls.fileForPartialStatus.valid ||
      this.form.controls.dre.controls.fileForFullStatus.valid
    ) {
      this.fileUploadInProgress = true;
      this.moveFormFacade
        .uploadMoveInDREFile(
          this.facade.state$.value.reference,
          this.registration.id,
          file,
          this.form.controls.dre.controls.dreStatus.value as UploadDocumentParamsDreCompletionStatusEnumCuzoApi
        )
        .pipe(
          take(1),
          catchError((error) => {
            this.fileUploadInProgress = false;
            return error;
          })
        )
        .subscribe(() => (this.fileUploadInProgress = false));
    }
  }

  private hasMeter(deliveryPoints: DeliveryPoint[], type: EnergyType): boolean {
    return deliveryPoints.some(({ energyType }) => energyType === type);
  }

  private getForm(currentStep: NewMetersPages, nextStep: NewMetersPages, navigateBack = false): MoveFormFrontend {
    const { electricity, gas } = this.form?.value?.meters;
    const elecIndexes =
      electricity?.indexes?.map((index) => ({ ...index, value: `${index?.unit ?? 0}.${index?.decimal ?? 0}` })) || null;
    const gasIndexes =
      gas?.indexes?.map((index) => ({ ...index, value: `${index?.unit ?? 0}.${index?.decimal ?? 0}` })) || null;
    const selectedDreStatus = this.form.controls.dre.controls.dreStatus.value;
    const dre: NewMetersStepDre = {
      dreStatus: selectedDreStatus,
      fileName:
        selectedDreStatus === UploadDocumentParamsDreCompletionStatusEnumCuzoApi.PARTIAL
          ? this.form.controls.dre.controls.fileForPartialStatus.value?.name
          : this.form.controls.dre.controls.fileForFullStatus.value?.name,
      modifiedWithoutSave: navigateBack,
    };
    const meters: { electricity?: ElectricityMeterFrontend; gas?: GasMeterFrontend } = {
      ...(this.meters.hasElectricity && {
        electricity: {
          ...electricity,
          indexes: elecIndexes,
        },
      }),
      ...(this.meters.hasGas && {
        gas: {
          ...gas,
          indexes: gasIndexes,
        },
      }),
    };

    const newForm = this.mergeFormToCurrentState(currentStep, nextStep, dre, meters);
    this.setModifiedState(newForm, currentStep, navigateBack);
    return newForm;
  }

  private mergeFormToCurrentState(
    currentStep: NewMetersPages,
    nextStep: NewMetersPages,
    dre: NewMetersStepDre,
    meters: { electricity?: ElectricityMeterFrontend; gas?: GasMeterFrontend }
  ): MoveFormFrontend {
    const newForm: MoveFormFrontend = {
      ...this.moveFormFacade.state$?.value?.form,
      newMeters: {
        ...this.moveFormFacade.state$?.value?.form?.newMeters,
        ...(currentStep === NewMetersPages.DRE && { dre }),
        ...(currentStep === NewMetersPages.ELECTRICITY && {
          meters: {
            ...this.moveFormFacade.state$?.value?.form?.newMeters.meters,
            electricity: {
              ...this.moveFormFacade.state$?.value?.form?.newMeters.meters.electricity,
              ...meters?.electricity,
            },
          },
        }),
        ...(currentStep === NewMetersPages.GAS && {
          meters: {
            ...this.moveFormFacade.state$?.value?.form?.newMeters.meters,
            gas: { ...this.moveFormFacade.state$?.value?.form?.newMeters.meters.gas, ...meters?.gas },
          },
        }),
        step: nextStep,
      },
    };

    return newForm;
  }

  private setModifiedState(
    form: MoveFormFrontend,
    currentStep: NewMetersPages,
    navigateBack = false
  ): MoveFormFrontend {
    if (currentStep === NewMetersPages.DRE) {
      form.newMeters.dre.modifiedWithoutSave = navigateBack;
    } else if (currentStep === NewMetersPages.ELECTRICITY) {
      form.newMeters.meters.electricity.modifiedWithoutSave = navigateBack;
    } else if (currentStep === NewMetersPages.GAS) {
      form.newMeters.meters.gas.modifiedWithoutSave = navigateBack;
    }
    return form;
  }

  private getUpdatedRegistration() {
    const registration = this.registration;
    if (this.step === NewMetersPages.DRE) {
      return this.setMoveInDateData(registration);
    } else if (this.step === NewMetersPages.ELECTRICITY && this.meters.hasElectricity) {
      return this.setElectricityData(registration);
    }
    return this.setGasData(registration);
  }

  private setMoveInDateData(registration: MoveInRegistration): MoveInRegistration {
    registration.sites[0].deliveryPoints[0].dreDocumentStatus = this.form.controls.dre.controls.dreStatus.value;

    return registration;
  }

  private setElectricityData(registration: MoveInRegistration): MoveInRegistration {
    const electricityFormControls = this.form.controls.meters.controls.electricity.controls;

    const [site] = this.registration?.sites;
    site.hasSolarPanels = electricityFormControls.hasSolarPanels.value;
    site.solarPanelKVA = electricityFormControls.solarPanelsKva.value;
    site.peakSolarPower = electricityFormControls.peakPower.value;
    site.hasInjectionTariff = electricityFormControls.injectionTariff.value;
    site.solarPanelsInstallationDateStarting2024 =
      electricityFormControls.solarPanelsInstallationDateStarting2024.value;
    const deliveryPointIndex = site?.deliveryPoints?.findIndex(
      ({ energyType }) => energyType === EnergyType.ELECTRICITY
    );
    const meterType = this.getMeterTypeBasedOnExcl(
      electricityFormControls.meterType.value,
      electricityFormControls.exclusiveNight.value
    );
    const deliveryPoint = site?.deliveryPoints?.[deliveryPointIndex];
    deliveryPoint.code = electricityFormControls.ean.value;
    deliveryPoint.billingMeterType = meterType;
    deliveryPoint.switchType = this.setSwitchType(electricityFormControls.noMeter.value);
    deliveryPoint.meters = [
      {
        ...deliveryPoint?.meters?.[0],
        number: electricityFormControls.number.value,
        type: meterType,
        smartMeter: electricityFormControls.smartMeter.value,
        registers: electricityFormControls.indexes.value.map((index) => ({
          timeFrame: index?.timeFrame,
          type: index?.type,
          value: `${index?.unit ?? 0}.${index?.decimal ?? 0}`,
        })),
      },
    ];
    return registration;
  }

  // We have speacial input for exclusive night, so we need to check if it is selected and update meterType to MONO_EXC or BI_EXC
  private getMeterTypeBasedOnExcl(selectedMeterType: MeterType, isExclusiveNight: boolean): MeterType {
    if (!isExclusiveNight) {
      return selectedMeterType;
    } else {
      return MeterType.MONO === selectedMeterType ? MeterType.MONO_EXC : MeterType.BI_EXC;
    }
  }

  private setGasData(registration: MoveInRegistration): MoveInRegistration {
    const gasFormControls = this.form.controls.meters.controls.gas.controls;

    const site = this.registration?.sites?.[0];
    const deliveryPointIndex = site?.deliveryPoints?.findIndex(({ energyType }) => energyType === EnergyType.GAS);
    const deliveryPoint = site?.deliveryPoints?.[deliveryPointIndex];
    deliveryPoint.code = gasFormControls.ean.value || '';
    deliveryPoint.switchType = this.setSwitchType(gasFormControls.noMeter.value);
    deliveryPoint.meters = [
      {
        number: gasFormControls.number.value,
        type: gasFormControls.meterType.value,
        registers: gasFormControls.indexes.value.map((index) => ({
          timeFrame: index?.timeFrame,
          type: index?.type,
          value: `${index?.unit ?? 0}.${index?.decimal ?? 0}`,
        })),
      },
    ];

    return registration;
  }

  private setSwitchType(noMeter: boolean): SwitchType {
    return noMeter ? SwitchType.MOVINGANDNEWBINDING : SwitchType.MOVING;
  }
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AnalyticsService } from '../../../../../../../../../shared/modules/analytics/services/analytics.service';
import { myEnergyEvents } from '../../../../../../../../../core/analytics/models/event-defaults';
import { Contract } from '../../../../../../../contracts/models/contract.interface';
import { TranslateService } from '@ngx-translate/core';
import { StepTypes } from '@app/modules/customer-zone/consumption/services/index-modal/index-modal.service';
import { IndexEncodingOrigin } from '@app/modules/customer-zone/consumption/models/consumption.interface';

@Component({
  selector: 'if-all-good',
  templateUrl: './all-good.component.html',
  styleUrls: ['./all-good.component.scss'],
})
export class AllGoodComponent implements OnInit {
  @Input() origin?: IndexEncodingOrigin = IndexEncodingOrigin.default;
  @Input() type: StepTypes.allGood | StepTypes.indexPosted;
  @Input() contract: Contract;
  @Output() formReady = new EventEmitter();
  protected indexEncodingOrigin = IndexEncodingOrigin;

  constructor(private analyticsService: AnalyticsService, private translateService: TranslateService) {}

  ngOnInit(): void {
    this.analytics();
  }

  continue() {
    this.formReady.emit();
  }

  private analytics() {
    this.analyticsService.push({
      ...myEnergyEvents(`${this.translateService.currentLang}/myenergy-flow/deposit-ok`),
      myEnergy: {
        energyType: this.contract.type,
        ean: this.contract.deliveryPointReference,
      },
    });
  }
}

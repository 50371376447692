<div
  *ngIf="paymentPlanSlices"
  class="overflow-x-auto bg-white rounded-4xl !border p-4 md:p-8 te-border-gradient-home box-shadow-small"
>
  <table class="slices table w-full">
    <thead payment-plan-headers class="row"></thead>
    <tbody>
      <tr *ngFor="let slice of paymentPlanSlices" payment-plan-slice [slice]="slice"></tr>
    </tbody>
  </table>
</div>

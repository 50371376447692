<ng-container *ngIf="sites">
  <div
    *ngIf="sites.length > 1 || (sites.length === 1 && version === 'big')"
    class="sites-switcher sites-switcher--{{ version }}"
  >
    <div *ngIf="sites.length === 1">
      <ng-template
        *ngIf="activeSite"
        [ngTemplateOutlet]="activeAddress"
        [ngTemplateOutletContext]="{ activeSite: activeSite, multi: false }"
      >
      </ng-template>
    </div>

    <div *ngIf="sites.length > 1">
      <div *ngIf="activeSite" class="sites-switcher__list-wrapper" ngbDropdown>
        <div class="sites-switcher__active" id="dropdownBasic2" ngbDropdownToggle>
          <ng-template
            [ngTemplateOutlet]="activeAddress"
            [ngTemplateOutletContext]="{ activeSite: activeSite, multi: true }"
          >
          </ng-template>
        </div>
        <ul class="card sites-switcher__list" ngbDropdownMenu aria-labelledby="dropdownBasic2">
          <ng-container *ngFor="let site of sites">
            <li
              ngbDropdownItem
              *ngIf="site.id !== activeSite.id"
              (click)="switchSite(site.id)"
              [analyticsEvent]="'personalBanner:click:change-address'"
            >
              {{ site.formattedAddress }}
            </li>
          </ng-container>
        </ul>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #activeAddress let-activeSite="activeSite" let-multi="multi">
  <h2 class="sites-switcher__title" [class.multisites]="multi">
    <ng-container *ngIf="version === 'big'; else longAddress">
      {{ activeSite?.streetName }} {{ activeSite?.streetNumber }}{{ activeSite?.boxNumber ? ' / ' : ''
      }}{{ activeSite?.boxNumber }}
    </ng-container>
    <ng-template #longAddress>
      {{ activeSite?.formattedAddress }}
    </ng-template>
    <svg
      *ngIf="multi"
      [class.scale-125]="version === 'big'"
      class="inline-block transform-gpu ml-4"
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M5.25049 8L10.2505 13L15.2505 8H5.25049Z" fill="#ED0000" />
    </svg>
  </h2>
  <div class="font-normal text-base" *ngIf="version === 'big'">
    <span> {{ activeSite?.zipCode }} {{ activeSite?.town }} </span>
    <span class="inline-block w-full sm:w-auto sm:border-l sm:border-grey-dark sm:ml-4 sm:pl-4">
      {{ 'header.secondaryMenu.helloUser.clientNumber' | translate }} {{ activeReference }}
    </span>
  </div>
</ng-template>

<div class="relative mt-36 p-8 lg:p16 rounded-4xl bg-grey-light shadow-lg sm:text-center">
  <div
    class="relative box-shadow-small bg-appleblue rounded-full h-52 w-52 flex flex-col items-center justify-center -mt-36 mx-auto md:absolute md:-mt-16 md:-ml-16"
  >
    <span class="block text-5xl font-display font-normal">
      {{ 'components.mgm.shareTool.ribbon' | translate : { discount: discount.promoDiscount } }}
    </span>
    <small class="block front-primary-400 text-center px-4">
      {{ 'general.untilTwo' | translate }} {{ discount.promoEndDate | date : 'dd.MM.yyyy' }}
    </small>
  </div>

  <h4 class="font-medium text-grey-dark text-center mt-12 text-2xl mb-4 sm:mb-8 sm:text-4xl sm:mt-0 md:mt-8">
    {{ 'components.mgm.shareTool.title' | translate }}
  </h4>

  <p
    class="font-normal text-gray-700 text-center"
    [innerHTML]="'components.mgm.shareTool.intro' | translate : { discount: discount.promoDiscount } | nl2br"
  ></p>

  <div class="my-8 md:flex lg:max-w-6xl lg:mx-auto">
    <div class="absolute opacity-0 md:relative md:opacity-100 md:block md:flex-grow md:relative">
      <input
        [ngxClipboard]="inputTarget"
        (cbOnSuccess)="copied($event)"
        class="form__input w-full border border-grey-dark60"
        type="text"
        readonly
        value="{{ shareLink }}"
        [analyticsEvent]="'mgm:share:Copy'"
        #inputTarget
      />
      <span class="link-copied icon-ok-circle" *ngIf="linkCopied">
        <img src="./assets/img/check.svg" alt="" class="inline-block mr-2" />
        {{ 'components.mgm.shareTool.copied' | translate }}
      </span>
    </div>
    <div ngbDropdown placement="bottom-left" class="inline-block w-full md:w-auto bg-primary rounded-2xl md:-ml-2">
      <div ngbDropdownToggle id="share-dropdown">
        <span class="share-btn">
          {{ 'components.mgm.shareTool.share.cta' | translate }}
        </span>
      </div>
      <ul ngbDropdownMenu aria-labelledby="share-dropdown" class="share-list">
        <li class="share-list__item" ngbDropdownItem>
          <a [ngxClipboard]="inputTarget" (cbOnSuccess)="copied($event)" [analyticsEvent]="'mgm:share:Copy'">
            <img src="./assets/img/icons/standalone/default/clipboard@2x.svg" class="inline-block pr-2" width="25" />
            {{ 'components.mgm.shareTool.share.copy' | translate }}
          </a>
        </li>
        <li class="share-list__item md:hidden" ngbDropdownItem>
          <a target="_blank" [href]="fbShareLink" [analyticsEvent]="'mgm:share:Messenger'">
            <img src="./assets/img/icons/standalone/default/messenger@2x.svg" class="inline-block pr-2" width="25" />
            {{ 'components.mgm.shareTool.share.messenger' | translate }}
          </a>
        </li>
        <li class="share-list__item md:hidden" ngbDropdownItem>
          <a target="_blank" [href]="waShareLink" [analyticsEvent]="'mgm:share:Whatsapp'">
            <img src="./assets/img/icons/standalone/default/whatsapp@2x.svg" class="inline-block pr-2" width="25" />
            {{ 'components.mgm.shareTool.share.whatsapp' | translate }}
          </a>
        </li>
        <li class="share-list__item" ngbDropdownItem>
          <a [href]="mailShareLink" [analyticsEvent]="'mgm:share:Email'">
            <img src="./assets/img/icons/standalone/default/email@2x.svg" class="inline-block pr-2" width="25" />
            {{ 'general.email' | translate }}
          </a>
        </li>
        <li class="share-list__item md:hidden" ngbDropdownItem>
          <a [href]="smsShareLink" [analyticsEvent]="'mgm:share:Sms'">
            <img src="./assets/img/icons/standalone/default/text@2x.svg" class="inline-block pr-2" width="25" />
            {{ 'general.sms' | translate }}
          </a>
        </li>
        <li class="share-list__item" ngbDropdownItem>
          <a (click)="fbShare()" [analyticsEvent]="'mgm:share:FacebookShare'">
            <img src="./assets/img/icons/standalone/default/facebook@2x.svg" class="inline-block pr-2" width="25" />
            {{ 'components.mgm.shareTool.share.facebook' | translate }}
          </a>
        </li>
      </ul>
    </div>
  </div>
  <p class="text-center my-8">
    {{ 'components.mgm.shareTool.yourCode' | translate }}:
    <span class="text-2xl pl-4 sm:text-4xl">{{ reference }} </span>
  </p>
</div>

<div class="my-16 px-8 sm:my-32 xl:max-w-screen-xl xl:w-full xl:mx-auto">
  <div class="md:grid md:grid-cols-3 gap-16">
    <div>
      <h2 class="mb-8 font-display font-normal text-4xl te-text-gradient-home inline-block">
        {{ 'pages.selfBilling.content.title' | translate }}
      </h2>
      <div [innerHTML]="'pages.selfBilling.content.description' | translate"></div>
      <a
        [href]="'pages.selfBilling.content.button.link' | translate"
        target="_blank"
        class="btn btn-primary inline-block font-bold mb-4 mt-10 md:mb-0"
      >
        {{ 'pages.selfBilling.content.button.label' | translate }}
      </a>
      <img src="./assets/img/solar-panel.png" class="w-full mt-8 mb-12 sm:mt-16" />
    </div>
    <div class="col-span-2">
      <div class="sm:flex sm:flex-wrap">
        <div class="sm:w-1/2 sm:pr-16 mt-8">
          <div class="w-20 h-20 rounded-full flex justify-center items-center bg-appleblue font-medium text-3xl">
            <p>1</p>
          </div>
          <h3 class="text-2xl font-medium mt-8">
            {{ 'pages.selfBilling.steps.one.title' | translate }}
          </h3>
          <div [innerHTML]="'pages.selfBilling.steps.one.description' | translate"></div>
        </div>
        <div class="sm:w-1/2 mt-8">
          <div class="w-20 h-20 rounded-full flex justify-center items-center bg-yellow font-medium text-3xl">
            <p>2</p>
          </div>
          <h3 class="text-2xl font-medium mt-8">
            {{ 'pages.selfBilling.steps.two.title' | translate }}
          </h3>
          <div [innerHTML]="'pages.selfBilling.steps.two.description' | translate"></div>
        </div>
        <div class="mt-8 sm:w-1/2 sm:mt-16 sm:pr-16">
          <div class="w-20 h-20 rounded-full flex justify-center items-center bg-appleblue font-medium text-3xl">
            <p>3</p>
          </div>
          <h3 class="text-2xl font-medium mt-8">
            {{ 'pages.selfBilling.steps.three.title' | translate }}
          </h3>
          <div [innerHTML]="'pages.selfBilling.steps.three.description' | translate"></div>
        </div>
        <div class="mt-8 sm:w-1/2 sm:mt-16">
          <div class="w-20 h-20 rounded-full flex justify-center items-center bg-yellow font-medium text-3xl">
            <p>4</p>
          </div>
          <h3 class="text-2xl font-medium mt-8">
            {{ 'pages.selfBilling.steps.four.title' | translate }}
          </h3>
          <div [innerHTML]="'pages.selfBilling.steps.four.description' | translate"></div>
        </div>
      </div>
    </div>
  </div>
</div>
